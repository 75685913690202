import React, { useContext } from "react";
import moment from "moment/moment";
import "moment/locale/mk";
import { sensorsNames } from "../../config/filter";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AlarmContext } from "../../context/alarm.context";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const SingleAlarm = ({ alarm, station }) => {
  const { deleteAlarm } = useContext(AlarmContext);
  const { t } = useTranslation();

  const handleDeleteAlarm = (alarmID) => {
    const options = {
      title: t("deleteAlarm"),
      message: t("confirmAlarmDeletion"),
      buttons: [
        {
          label: t("confirm"),
          onClick: () => deleteAlarm(alarmID),
        },
        {
          label: t("decline"),
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "modal-overlay",
    };

    confirmAlert(options);
  };
  return (
    <tr className="odd" key={alarm._id}>
      <td>{t(alarm.filter.replace(1, ""))}</td>

      <td className="text-center">
        <span className="badge py-3 px-4 fs-7 badge-light-primary">
          {alarm.comparing}
        </span>
      </td>

      <td className="text-center">
        {alarm.value} {sensorsNames[alarm.filter]?.symbol}
      </td>
      {!station ? (
        <td className="text-left">
          <a href={`/station/${alarm.station._id}`}>
            {i18next.language === "mk" || i18next.language === "sr"
              ? alarm.station.cyrilicName
              : alarm.station.name}
          </a>
        </td>
      ) : (
        ""
      )}
      <td className="text-end fw-semibold fs-7">
        {moment(alarm.created).locale(i18next.language).format("LLL")}
      </td>
      <td>
        <a onClick={() => handleDeleteAlarm(alarm._id)}>
          <i className="fas fa-trash text-danger"></i>
        </a>
      </td>
    </tr>
  );
};

export default SingleAlarm;
