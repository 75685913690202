import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import "./index.css";
import "./assets/css/custom.css";

import { store } from "./store/store";

import "./services/i18n";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { LoaderProvider } from "./context/loader.context";
import { StationProvider } from "./context/station.context";
import { AuthProvider } from "./context/auth.context";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AlarmProvider } from "./context/alarm.context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <LoaderProvider>
        <AuthProvider>
          <AlarmProvider>
            <StationProvider>
              <App />
              <ToastContainer position="bottom-center" autoClose={5000} />
            </StationProvider>
          </AlarmProvider>
        </AuthProvider>
      </LoaderProvider>
    </Provider>
  </BrowserRouter>
);
