import { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth.context";
import { LoaderContext } from "../context/loader.context";

const Register = () => {
	const { register, handleSubmit } = useForm();
	const { setPageLoader } = useContext(LoaderContext);
	const { authRegister } = useContext(AuthContext);

	const navigate = useNavigate();

	const handleLogin = async ( data ) => {
		setPageLoader(true);
		try {
			await authRegister(data);
			navigate('/login');
		} catch (e) {
			console.log(e);
		}
		setPageLoader(false);
	}

	return (
		<div className="auth">
			<div className="card rounded-3 w-md-550px">
				<div className="card-body p-10 p-lg-20">
					<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(handleLogin)}>
						<div className="text-center mb-11">
							<h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
						</div>

						<div className="fv-row mb-8 fv-plugins-icon-container">
							<input type="text" className="form-control" name="name" placeholder="Enter full name" {...register("name") } />
						</div>

						<div className="fv-row mb-8 fv-plugins-icon-container">
							<input type="text" className="form-control" autoComplete="off" name="email" placeholder="Enter email" {...register("email") } />
						</div>

						<div className="fv-row mb-3 fv-plugins-icon-container">
							<input type="password" className="form-control" autoComplete="off" placeholder="Enter password" {...register("password")} />
						</div>

						<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
							<div></div>
							<Link to='/' className="link-primary">Forgot Password?</Link>
						</div>

						<div className="d-grid mb-10">
							<button type="submit" className="btn btn-primary">Register</button>
						</div>

						<div className="text-gray-500 text-center fw-semibold fs-6">Have an account? <Link to="/login" className="link-primary me-1">Log in</Link></div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Register;