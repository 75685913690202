import { Routes, Route } from "react-router-dom";

import Home from "./routes/home";
import Login from "./routes/login";
import Register from "./routes/register";
import ProtectedRoute from "./middleware/protected.middleware";
import UnprotectedRoute from "./middleware/unprotected.middleware";
import Header from "./components/header.component";
import SingleStation from "./routes/single-station";
import { useContext, useEffect } from "react";
import { LoaderContext } from "./context/loader.context";
import HistoricalStation from "./routes/single-station-historical";
import WebviewDiseases from "./components/webview/diseases.component";
import Alarms from "./routes/alarms";
import Events from "./routes/events";
import Stations from "./routes/stations";
import WebviewHistorical from "./components/webview/historical.component";
import WebviewAbout from "./components/webview/aboutAgrio.component";
import WebviewTermsConditions from "./components/webview/TermsAndConditions.component";
import PrivacyPolicy from "./components/webview/PrivacyPolicy.component";
import AboutFitr from "./components/webview/aboutFirt.component";
import Landing from "./routes/landing";
import i18next from "i18next";
import "moment/locale/mk";
import "moment/locale/sr";
import "moment/locale/sq";

function App() {
  const { pageLoader } = useContext(LoaderContext);

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem("language") ?? "mk");
  });

  return (
    <div className={`page ${pageLoader ? "page-loading" : ""}`}>
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Header />
                <Home />
              </ProtectedRoute>
            }
          />

          <Route
            path="/station/:stationSlug"
            element={
              <ProtectedRoute>
                <Header />
                <SingleStation />
              </ProtectedRoute>
            }
          />

          <Route
            path="/history/:stationSlug"
            element={
              <ProtectedRoute>
                <Header />
                <HistoricalStation />
              </ProtectedRoute>
            }
          />

          <Route
            path="/alarms"
            element={
              <ProtectedRoute>
                <Header />
                <Alarms />
              </ProtectedRoute>
            }
          />

          <Route
            path="/events"
            element={
              <ProtectedRoute>
                <Header />
                <Events />
              </ProtectedRoute>
            }
          />

          <Route
            path="/stations"
            element={
              <ProtectedRoute>
                <Header />
                <Stations />
              </ProtectedRoute>
            }
          />

          <Route
            path="/login"
            element={
              <UnprotectedRoute>
                <Login />
              </UnprotectedRoute>
            }
          />

          <Route
            path="/register"
            element={
              <UnprotectedRoute>
                <Register />
              </UnprotectedRoute>
            }
          />

          <Route path="/webview/disease/:id" element={<WebviewDiseases />} />
          <Route
            path="/webview/disease/:id/:lang"
            element={<WebviewDiseases />}
          />
          <Route
            path="/webview/historical/:filter/:id"
            element={<WebviewHistorical />}
          />
          <Route
            path="/webview/historical/:filter/:id/:lang"
            element={<WebviewHistorical />}
          />
          <Route path="/webview/about-agrio/" element={<WebviewAbout />} />
          <Route path="/webview/about-agrio/:lang" element={<WebviewAbout />} />
          <Route
            path="/webview/terms-and-conditions/"
            element={<WebviewTermsConditions />}
          />
          <Route
            path="/webview/terms-and-conditions/:lang"
            element={<WebviewTermsConditions />}
          />
          <Route path="/webview/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/webview/privacy-policy/:lang"
            element={<PrivacyPolicy />}
          />
          <Route path="/webview/about-fitr/" element={<AboutFitr />} />
          <Route path="/webview/about-fitr/:lang" element={<AboutFitr />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
