import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const HistoricalParams = ({ activeType, setActiveType, station }) => {
  const { t } = useTranslation();
  // Checking/Unchecking historical type
  const handleHistoricalType = (id) => {
    if (activeType.indexOf(id) === -1) {
      if (activeType.length < 3) {
        // Here something
        setActiveType([...activeType, id]);
      } else {
        let tmp = activeType.filter((item, i) => i !== 0);
        tmp.push(id);
        setActiveType(tmp);
      }
    } else {
      setActiveType(activeType.filter((item) => item !== id));
    }
  };

  const historical_data = [
    {
      id: "all",
      name: t("selectFilter"),
      icon: "fas fa-cubes",
    },
    {
      id: "airTemperature",
      name: t("temperature"),
      icon: "fas fa-temperature-low",
    },
    {
      id: "airPressure",
      name: t("airPressure"),
      icon: "fas fa-wind",
    },
    {
      id: "airHumidity",
      name: t("airHumidity"),
      icon: "fas fa-tint",
    },
    {
      id: "earthHumidity1",
      name: t("earthHumidity"),
      icon: "fas fa-tint",
    },
    {
      id: "dewPoint",
      name: t("dewPoint"),
      icon: "fas fa-temperature-high",
    },
    {
      id: "earthTemperature1",
      name: t("earthTemperature"),
      icon: "fas fa-temperature-high",
    },
    {
      id: "leafWetness1",
      name: t("leafWetness"),
      icon: "fas fa-leaf",
    },
    {
      id: "precipation",
      name: t("rain"),
      icon: "fas fa-cloud-rain",
    },
    {
      id: "windSpeed",
      name: t("windSpeed"),
      icon: "fas fa-wind",
    },
    {
      id: "solarRadiation",
      name: t("solarRadiation"),
      icon: "fas fa-cloud-rain",
    },
    {
      id: "evapotranspiration",
      name: t("evapotranspiration"),
      icon: "fas fa-wind",
    },
  ];

  return (
    <div className="card-body d-flex justify-content-between flex-column flex-wrap p-0 historical-tabs">
      <Fragment key="all">
        <div
          key="all"
          className={`historical-tab ${
            activeType.length === 0 ? "active" : ""
          }`}
        >
          <span>
            <i className={historical_data[0].icon}></i>
          </span>
          <div className="fs-5 ms-3">{historical_data[0].name}</div>
          <i
            className="fas fa-exclamation-circle ms-1 fs-7 custom-tooltip"
            data={t("filterLimit")}
          ></i>
        </div>
        <div className="separator separator-dashed"></div>
      </Fragment>
      {historical_data.slice(1).map((data) => {
        if( station?.lastData[0] && station.lastData[0][data.id] ) {
        return (
        <Fragment key={data.id}>
          <div
            key={data.id}
            onClick={() => handleHistoricalType(data.id)}
            className={`historical-tab ${
              activeType.indexOf(data.id) === -1 ? "" : "active"
            }`}
          >
            <span>
              <i className={data.icon}></i>
            </span>
            <div className="fs-5 ms-3">{data.name}</div>
          </div>
          <div className="separator separator-dashed"></div>
        </Fragment>
      ) } })}
    </div>
  );
};

export default HistoricalParams;
