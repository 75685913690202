import React from "react";
import "../webview.style.css";

const PrivacyEn = () => {
  return (
    <div className="flex-lg-row-auto">
      <div className="card ">
        <div className="card-body">
          <div className="mb-7">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">PRIVACY POLICY</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              This document describes the policy of AGROPTIMA DOOEL, and its
              controlled affiliates ("we" or the "Vendor"), regarding Personal
              Information and Equipment Data.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Summary</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              We collect contact information, transaction information, and
              financial account information (collectively, Personal Information)
              from you when you purchase products or services, register at our
              website(s) or otherwise interact with us. We may obtain Personal
              Information from third parties (e.g. Trade Registry Agencies,
              etc.) to enhance our files or as needed to complete transactions.
              <br />
              <br />
              We may collect information using Cookies and Other Data Collection
              Technologies. This information is treated as Personal Information,
              if it identifies or makes identifiable a natural person. <br />
              <br />
              We do not share Personal Information with other companies for
              their own use. We may, however, disclose Personal Information
              where required by law. <br />
              <br />
              We have a formal information security program to protect Personal
              Information and Equipment Data. <br />
              <br />
              You can limit the information you provide to us. Additionally, you
              can limit the communications that we send to you by contacting us
              using at the contact information below, or by clicking
              “Unsubscribe” at the bottom of our marketing messages: <br />
              <br />
              AGROPTIMA <br />
              DOO ul. 15-ti Korpus br 2 <br />
              EDB 4043018524509 <br />
              Tel.: +389 078 294 192 <br />
              agropskopje@gmail.com
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">General</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              This Privacy Policy explains how we collect, use and disclose
              Personal Information and Equipment Data. This Privacy Policy also
              answers specific questions that you may have regarding the privacy
              and security of collected information and data.
              <br />
              <br />
              We may also have additional privacy policies as needed to comply
              with local laws. Our products and services may be distributed
              through a network of distributors, independently owned and
              operated businesses, which may have their own privacy policies.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Types of Information & Data We Collect
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Personal Information is information that can be used to identify,
              locate or contact you. It also includes other information about
              you that we tie to Personal Information. We collect the following
              types of Personal Information:
            </p>

            <div className="mb-8">
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Contact Information that allows us to identify and to
                    communicate with you, such as your name, phone number,
                    username, mailing address, email address, IP address, and
                    language preference.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Business Information that helps us understand who you are
                    and what you want in order to offer you products and
                    services that may interest you, such as property, farm or
                    business information, your general location, and other
                    demographic information.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Transaction Information about how you interact with us, our
                    distributors and business partners, including purchases,
                    equipment IDs, inquiries, and customer accounts that helps
                    us improve our services to you, and offer you discounts and
                    promotions.
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Financial Account Information as needed to complete your
                    purchase, such as company data, VAT ID, legal
                    representative, etc.
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    User Account Information, which you enter in the software in
                    order to use it, such as email, equipment location, fields
                    data, settings, etc.
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Mobile Device Information – in order to be able to use the
                    software on mobile devices such as smartphones or tablets,
                    depending upon device and operation system specifics, you
                    may need to provide access to:
                    <br />
                    Approximate location (network-based);
                    <br />
                    Precise location (GPS and network-based);
                    <br />
                    Camera – pictures and videos;
                    <br />
                    View network connections;
                    <br />
                    Control flashlight;
                    <br />
                    Full network access.
                    <br />
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    You have access to Personal Information that is stored in
                    the software about you. We take reasonable steps to permit
                    you to correct, amend, or delete information that is
                    demonstrated to be inaccurate or incomplete. You have the
                    right to overwrite your information or to upload new files
                    by yourself. Your email address is collected for
                    communication purposes and to identify your user account in
                    the software. A password is used to enable a secure log in
                    procedure.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <p className="fw-semibold fs-6 text-gray-600">
              Equipment Data is data generated by the use of or collected by the
              equipment you purchased from us, and stored on our servers.
              Equipment Data includes:
            </p>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Precipitation quantity;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Soil temperature;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Soil moisture;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">Wind speed;</div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Air temperature;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Relative air humidity;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Atmospheric pressure;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Wind direction;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Leaf wetness;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Date and time;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Battery voltage;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Solar panel voltage;
              </div>
            </div>

            <p className="fw-semibold fs-6 text-gray-600 mt-5">
              In order to gain maximum utility from the equipment and software,
              you may share Equipment Data pertaining to the territorial
              coverage of your equipment within a common agriculture weather
              network according to the terms and conditions set out in the Terms
              of Use. In this case, you may get access to data from neighbouring
              equipment, notifications about disease risks, etc. When you
              purchase our subscription with data sharing, you explicitly agree
              that the Equipment Data, and any and all database compiled of
              them, become exclusive ownership of the Vendor according to the
              terms and conditions set out in the Terms of Use.
              <br />
              <br />
              We may process and/or save data from external systems (such as
              Google Maps, weather forecast providers, plant growth and disease
              model providers, etc.) or connect information in order to provide
              the services requested by you.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              How We Collect Information & Data
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Directly from You – we collect Personal Information directly from
              you. We will ask you for Personal Information (e.g. Contact
              Information and Business Information) when you interact with us,
              such as when you register on our websites, sign up to receive a
              newsletter, or make a purchase. We may collect additional Business
              Information from third party data suppliers who enhance our files
              and help us better understand our customers, or from social media
              sites such as Facebook, if you “Like” our pages or join our
              communities. We also collect Personal Information when you use our
              mobile applications or contact us, such as for customer service
              purposes.
              <br />
              <br />
              From Our Controlled Affiliates, Distributors and Other Business
              Partners – when you purchase or lease a product or service, we
              collect Personal Information (e.g. Transaction Information and
              Financial Account Information) from our distributors and financial
              affiliates. We may also collect Personal Information from third
              parties (such as Trade Registry Agency) in order to complete your
              purchase.
              <br />
              <br />
              From Our Website – if you interact with us online, we use cookies
              and other technological tools to collect information about your
              computer and your use of our website and applications. We treat
              this information as Personal Information when it is associated
              with your Contact Information. For more information about cookies
              and other technologies, please see the section Cookies and Similar
              Collection Technologies below.
              <br />
              <br />
              From your mobile device(s) – we may receive location data from
              your smartphone or other geo- aware device. We treat this
              information as Personal Information, if it identifies or makes
              identifiable a natural person.
              <br />
              <br />
              From Your Equipment – we collect Equipment Data from your
              equipment via wireless data transfer technologies. This is not
              Personal Information.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Your Consent</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              By purchasing our products оr services and/or using our websites,
              you are explicitly consenting to us to collect, use and disclose
              your personal information in accordance with this Privacy Policy.
              If you do not agree to the collection, use and disclosure of your
              personal information in this way, please do not purchase our
              products, nor use our service and/ or the website or otherwise
              provide us with your personal information.
              <br />
              <br />
              We will only collect, use or disclose your personal information
              where you agree and it is lawful to do so. We may ask you to
              provide additional consent, if we need to use your personal
              information for purposes not covered by this Privacy Policy. You
              are not obliged to provide such consent but, if you decide not to,
              then your participation in certain activities may be restricted.
              If you provide additional consent, the terms of that consent shall
              prevail in the event of any conflict with the terms of this
              Privacy Policy.
              <br />
              <br />
              Please note, however, that we may process your personal
              information without consent, if we have a legitimate reason to do
              so, provided that such use will not have a prejudicial effect on
              your own rights, freedoms and interests. Any such use shall be in
              accordance with this Privacy Policy.
              <br />
              <br />
              You may withdraw your consent for us to use your personal data at
              any time by emailing us or contacting us at the address above.
              Withdrawal of your consent does not affect the lawfulness of any
              processing that took place prior to that withdrawal.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              How We Use Information & Data (the "Purposes")
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              We use Personal Information and Equipment Data for a variety of
              purposes, namely:
            </p>

            <div className="mb-8">
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Customer Service: the Vendor is entitled to access the
                    non-anonymised customer data set to provide the
                    contractually defined service, to fulfil orders for products
                    or services, and for other services related to your order,
                    such as product delivery, maintenance, warranty service,
                    leasing, etc.;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Technical Support: to provide support, including monitoring
                    and managing the availability of the products you purchased,
                    to deliver upgrades and product improvement program
                    communications, and for diagnostics and repairs, product
                    safety and recalls;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Marketing: to provide you with communications and offers for
                    products and services from us or our distributors and
                    partners, including offers based on your interests, personal
                    and business characteristics and location;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Surveys &amp; Promotional Events: to administer surveys,
                    polls, sweepstakes, contests, loyalty programs and other
                    promotional events;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Eligibility: to determine if you are eligible for certain
                    products, services or offers;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Communications: to provide you with information that may be
                    of interest, such as product news, company magazines,
                    catalogues, announcements, reminders, and technical service
                    bulletins; and
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Manage Our Everyday Business Needs: such as payment
                    processing and financial account management, product
                    development, product safety, contract management, website
                    administration, web-forum management, order or contract
                    fulfilment, analytics, fraud prevention, corporate
                    governance, reporting and legal compliance.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Why Information & Data are Disclosed by Us
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              We limit our sharing of Personal Information and Equipment Data as
              follows:
            </p>
            <div className="mb-8">
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    We may disclose Personal Information to outside parties when
                    we have a good faith belief that disclosure is reasonably
                    necessary to (a) protect the safety of any person from death
                    or serious bodily injury; (b) prevent fraud or abuse against
                    us or our users; (c) defend us and our affiliates or
                    personnel from any legal proceedings arising out of customer
                    data; (d) other cases provided for in the applicable
                    legislation.
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    We may disclose Personal Information to our business
                    partners and/or services providers which work on our behalf
                    and do not have an independent use of the information we
                    disclose to them as needed to support external auditing,
                    compliance and corporate governance functions. We will
                    require from such third parties to use the data for the
                    purposes for which it was disclosed and in compliance with
                    this Privacy Policy and applicable legislation. You may
                    disagree to such disclosure but we may not be able to fully
                    deliver our services to you.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    We will also disclose Personal Information when required to
                    do so by law, such as in response to a subpoena, including
                    to law enforcement agencies and courts in the countries
                    where we operate. We may disclose Equipment Data to third
                    parties in order to provide the services, requested by you,
                    such as data from neighbouring equipment, notifications
                    about disease risks, etc.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    If we are involved in a merger, acquisition, or sale of all
                    or a portion of our assets, you will be notified and/or
                    there will be a notice on our website of any change in
                    ownership or use of Personal Information, as well as any
                    choices you may have regarding this information.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Please note that we may also disclose information and data
                    that is not personally identifiable. For example, we may
                    publish reports that contain aggregated and statistical data
                    about our customers and equipment. These reports do not
                    contain information that would enable the recipient to
                    contact, locate or identify you.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Cookies and Similar Collection Technologies
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              When you visit our website, or use our mobile applications, we
              collect certain information by automated means, using technologies
              such as cookies, pixel tags, browser analysis tools, server logs
              and web beacons.
              <br />
              <br />
              For example, when you visit our website, we place cookies on your
              computer. Cookies are small text files that websites send to your
              computer or other Internet-connected device to uniquely identify
              your browser or to store information or settings in your browser.
              Cookies allow us to recognize you when you return. They also help
              us provide a customized experience and enable us to detect certain
              kinds of fraud.
              <br />
              <br />
              Pixel tags and web beacons are tiny graphic images placed on
              website pages or in our emails that allow us to determine whether
              you have performed a specific action. When you access these pages,
              or open or click an email, the pixel tags and web beacons generate
              a notice of that action. These tools allow us to measure response
              to our communications and improve our web pages and promotions.
              <br />
              <br />
              We collect many different types of information from cookies and
              other technologies. For example, we may collect information about
              the device you use to access our website, your operating system
              type, browser type, domain, and other system settings, as well as
              the language your system uses and the country and time zone where
              your device is located. Our server logs also may record the IP
              address or derivative of the device you use to connect to the
              Internet. An IP address is a unique identifier that devices use to
              identify and communicate with each other on the Internet. We may
              also collect information about the website you were visiting
              before you came to ours and the website you visit after you leave
              our site.
              <br />
              <br />
              In many cases, the information we collect using cookies and other
              tools is only used in a non- identifiable way, without reference
              to Personal Information. For example, we use information we
              collect about website users to optimize our websites and to
              understand website traffic patterns. In some cases, we do
              associate the information we collect using cookies and other
              technology with your Personal Information. Additionally, if the
              settings on your geo-aware mobile devices permit our mobile
              applications to collect location information, we will collect that
              information automatically. This Privacy Policy governs how we use
              all of this information when we associate it with your Personal
              Information.
              <br />
              <br />
              The Vendor has relationships with third party advertising
              companies to perform tracking and reporting functions for our web
              processing. These third-party advertising companies may place
              cookies on your computer so that they can display targeted
              advertisements to you. These third- party advertising companies
              should not collect Personal Information in this process, and we do
              not give Personal Information to them as part of this process.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Tell-A-Friend Functions
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              We offer “tell-a-friend” functionality on our websites. If you
              choose to use this function, we will automatically send your
              friend a one-time email with the information you specified and
              invite him or her to visit the website. We use this information
              for the sole purpose of sending this one-time email and do not
              retain the information.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Your Choices</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              You can limit the information you provide to us. You can also
              limit the communications that we send to you. To opt-out of
              marketing messages, you can visit our website and communicate your
              choice to the relevant privacy contact. Alternatively, you may
              opt-out of receiving future marketing messages by clicking
              “unsubscribe” at the bottom of marketing messages we send you.
              <br />
              <br />
              Please note that even if you opt-out of receiving marketing
              material, we may still need to contact you with important
              Transactional Information about your account or your equipment.
              For example, even if you opt-out of marketing messages, we will
              still send you a confirmation when you purchase something from our
              website as well as product safety communications.
              <br />
              <br />
              You can manage cookie preferences and opt-out of having cookies
              and other similar collection technologies used by adjusting the
              settings on your browser or interface. All browsers and interfaces
              are different, so visit the “help” section of your browser or
              interface to learn about cookie preferences and other privacy
              settings that may be available.
              <br />
              <br />
              You can manage how your mobile device and mobile browser share
              location information with us, as well as how your mobile browser
              handles cookies and related technologies by adjusting your mobile
              device privacy and security settings. Please refer to instructions
              provided by your mobile service provider or the manufacturer of
              your device to learn how to adjust your settings.
              <br />
              <br />
              You can also opt-out of sharing Equipment Data. To do this, you
              need to purchase a subscription without data sharing. In this case
              we will set up for you a dedicated access for your Equipment Data.
              If you have any questions about your choices or if you need any
              assistance with opting-out, please contact our Customer Service.
              You can also write us at the address above. If you send us a
              letter, please provide your name, address, email address, and
              information about the communications that you do not want to
              receive.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Access and Correction
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Vendor complies with applicable law regarding your ability to
              access, correct and delete your Personal Information for free. If
              you have an online or mobile app account, you are able at any time
              to log into your account to access and update certain information
              you provided to us. If you need assistance updating or deleting
              your Personal Information, please contact us at the address above.
              If you send us a letter, please provide your name, address, email
              address, and detailed information about the change you would like
              to make.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Information & Data Security
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Vendor has implemented an information and data security
              program that contains administrative, technical and physical
              controls that are designed to reasonably safeguard Personal
              Information and Equipment Data. For example, we use
              industry-standard encryption technology to secure your account
              passwords and other sensitive Personal Information.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Data storage and retention policy
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Your data are stored on our servers (Servers) co-located in
              enterprise-level data centres in Bulgaria, for which privacy
              policies, non-disclosure and contractual agreements apply. Unless
              and until you request removal of our access to and use of Personal
              Information we will store the data, provided such data storage is
              in compliance with all applicable laws and regulations of the
              jurisdictions where data is stored. We will not retain Personal
              Information for longer than necessary in relation to the purposes
              for which it was collected, or for which it is further processed.
              If particular retention periods are defined in the Terms of Use
              for using our equipment, software or services or in the contract
              with you, we will delete the data stored upon expiration of the
              retention periods provided such data storage is in compliance with
              all applicable laws and regulations of the jurisdictions where
              data is stored. You acknowledge and agree that data, once deleted
              from the Server(s), cannot be retrieved or re-created.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Transfer of Information & Data to Other Countries
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Prointegra is headquartered in Bulgaria and its affiliates,
              distributors and partners are located in various countries
              throughout the world. Personal Information and Equipment Data may
              be accessed by or transferred to Bulgaria or to our data
              processors (such as affiliates, distributors, partners, etc.)
              elsewhere in the world for the Purposes described in this Privacy
              Policy. When we access or transfer Personal Information and
              Equipment Data, we will do so subject to your prior explicit
              consent and in compliance with the applicable law. Additionally,
              we protect the privacy and security of Personal Information and
              Equipment Data, regardless of where it is processed or stored.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Privacy Policies of Third Parties
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              This Privacy Policy only addresses the use and disclosure of
              information and data by the Vendor. Other websites and
              applications that may be accessible through ours have their own
              privacy policies and practices. Our independent distributors,
              suppliers and business partners have their own privacy policies
              and practices too. We encourage you to familiarize yourself with
              the privacy policies provided by all third parties prior to
              providing them with information or taking advantage of an offer or
              promotion.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Children’s Privacy
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Vendor’s website and applications are not intended to collect
              Personal Information from children. If you believe your child is
              using our website, please contact us at the address above so we
              can investigate and delete any inappropriate information.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Forums, Product Reviews and Other Public Areas
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Our website or social network pages may contain forums and other
              areas where you can publicly post product reviews, communicate
              with others, and submit media content. Prior to posting in these
              areas, please read the applicable “Terms of Use” carefully. You
              have no privacy rights in public postings. All information you
              post will be accessible to anyone with Internet access, and any
              Personal Information you include in your posting may be read,
              collected, and used by others. For example, if you post your email
              address along with a product review, you may receive unsolicited
              messages. Please use caution when posting any Personal
              Information.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Job Applicants</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              If you have applied for employment with the us, the Personal
              Information submitted with your job application will be used for
              recruitment and other internal human resources purposes only.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Changes to this Privacy Policy
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              From time to time, we may update this Privacy Policy to reflect
              new or different privacy practices. We will place a notice on our
              homepage when we make material changes to this Privacy Policy.
              Additionally, if the changes will materially affect the way we use
              or disclose previously collected Personal Information, we may also
              notify you about the change by sending a notice to you.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Conclusion</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              By entering your personal data into our software and website and
              checking the „I agree to the Vendor’s Privacy Policy” checkbox,
              you accept that we are collecting, processing and, in limited
              cases described above, disclosing your Personal Information and
              Equipment Data in the manner described in this Privacy Policy and
              our Terms of Use.
            </p>
          </div>
          <div className="mb-8">
            <p className="fw-semibold fs-6 text-gray-800">
              Last Updated: 29 Jаnuary 2023
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyEn;
