import axios from "axios";
import { useContext, useState } from "react";
import { createContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoaderContext } from "./loader.context";

import {
  setActiveDiseases,
  setActiveStation,
  setStations,
} from "../store/stations/stations.action.js";
import { AuthContext } from "./auth.context";
import { toast } from "react-toastify";

export const StationContext = createContext({
  stations: {},
  setStations: () => {},
});

export const StationProvider = ({ children }) => {
  const { setLoader } = useContext(LoaderContext);
  const { logoutUser } = useContext(AuthContext);
  const token = useSelector((state) => state.user.token);
  const [notifications, setNotifications] = useState(null);

  const dispatch = useDispatch();

  /*------------------------------------------------------------------------------------
    // Register new user
    ------------------------------------------------------------------------------------*/
  const fetchStations = () => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}station/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(setStations(res?.data));
        setLoader(false);
      })
      .catch((err) => {
        logoutUser();
        setLoader(false);
      });
  };

  /*------------------------------------------------------------------------------------
    // Fetch Single Station
    ------------------------------------------------------------------------------------*/

  const fetchSingleStation = (id) => {
    setLoader(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}station/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(setActiveStation(res?.data));
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  /*------------------------------------------------------------------------------------
    // Fetch 
    ------------------------------------------------------------------------------------*/
  const fetchDisease = (station, disease, from = false, to = false) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}station/diseases/${station}`,
        { disease: disease, from: from, to: to },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(setActiveDiseases(res?.data));
      })
      .catch((err) => {});
  };

  const fetchNotifications = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}notifications`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        if (result?.status === 200) {
          setNotifications(result.data.notifications);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("error ");
        setNotifications([]);
        console.log(err);
      });
  };

  const value = {
    fetchStations,
    fetchSingleStation,
    fetchDisease,
    notifications,
    fetchNotifications,
  };
  return (
    <StationContext.Provider value={value}>{children}</StationContext.Provider>
  );
};
