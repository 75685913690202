// import { ACTION_TYPES } from './user.types';

const INITIAL_STATE = {
	userInformation: {},
	token: localStorage.getItem('token') ?? false
}

export const userReducer = (state = INITIAL_STATE, action) => {
	
	const { type, payload } = action;

	switch(type) {
		case ('SET_CURRENT_USER'):		
			localStorage.setItem('token', payload.token);

			return {
				...state,
				token: payload.token,
				userInformation: payload.user,
			}
		case ('CHECK_CURRENT_USER'):		
			return {
				...state,
				userInformation: payload.user,
			}
		case ('REMOVE_CURRENT_USER'):
			localStorage.removeItem('token');

			return {
				userInformation: {},
				token: false,
			}
		default:
			return state;
	}
}
