import React from "react";
import "../webview.style.css";

const PrivacyMk = () => {
  return (
    <div className="flex-lg-row-auto">
      <div className="card ">
        <div className="card-body">
          <div className="mb-7">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Политика за приватност
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Овој документ се однесува на политиката на АГРОПТИМА ДООЕЛ, и
              нејзините подружнички (“ние„ или “Продавач„) на кои се однесуваат
              Личните податоци и Податоците за опремата.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Краток преглед</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Ние ги прибираме вашите контакт информации, трансакциски
              информации и информации од финансиската сметка ( со еден збор,
              Лични податоци) кога ќе купите производ или добиете услуга, кога
              ќе се регистрирате на нашите веб страни или не контактирате на
              било кој начин. Може да ги добиеме Вашите Лични Податоци од трети
              лица( на пр. Трговски регистар, итн.) за да се подобрат датотеките
              или ако е потребно да се изврши наплата.
              <br />
              <br />
              Може да се приберат информации со користење Колачиња и Други
              Технологии за Прибирање податоци. Овие информации се третираат
              како Лични Податоци, и се користат за да се докаже идентитетот на
              лицето.
              <br />
              <br />
              Личните податоци не се споделуваат со други компании за нивна
              употреба. Но, Личните Податоци може да бидат споделени во случаи
              каде се налага со закон. <br />
              <br />
              Поседуваме формална програма за заштита на податоци за да се
              заштитат Личните податоци и Податоците за опремата. <br />
              <br />
              YВие може да ги ограничите податоците кои ќе ни ги доставите. Исто
              така, може да ја ограничите и комуникацијата која ви ја испраќаме,
              а потребно е само да воспоставите контакт на контакт информациите
              кои се наоѓаат подолу, или со откажување на претплатата, копчето е
              поставено најдолу на нашите маркетинг пораки: <br />
              <br />
              АГРОПТИМА ДОО <br />
              Ул.15-ти Корпус бр 2 <br />
              ЕДБ 4043018524509
              <br />
              Тел.:+389 078 294 192
              <br />
              agropskopje@gmail.com
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Општи услови</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Политиката за приватност објаснува како ги прибираме, користиме и
              споделуваме Личните Податоци и Податоците за опремата. Оваа
              Политика на Приватност исто така одговара на одредени прашања кои
              се однесуваат на приватноста и безбедноста на приберените податоци
              и информации.
              <br />
              <br />
              Исто така може да се појават дополнителни политики за приватност
              кои се потребни за да бидеме во согласност со локалните закони.
              Нашите производи и услуги може да се распределат низ мрежа од
              дистрибутери, претпријатија кои се во приватна сопственост , кои
              може имаат и нивни политики за приватност.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Видови на информации и податоци кои ги прибираме
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Лични податоци се информациите кои може да се користат за да Ве
              идентификуваме, лоцираме и контактираме. Исто така тука се
              вклучени и другите информации што ние ги поврзуваме со Лични
              Податоци. Ги прибираме следните видови на Лични Податоци:
            </p>

            <div className="mb-8">
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Контакт информации се оние кои ни овозможуваат да Ве
                    идентификуваме и да воспоставиме комуникација со Вас, како
                    што се Вашето име, телефонски број, корисничко име, адреса
                    на електронска пошта, IP адреса, како и изборот на јазик.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Бизнис информации кои помагаат да разбереме за кој се
                    однесува и што Вие би сакале да нарачате со цел да ви
                    понудиме производи и услуги кои можеби Ве интересирале, како
                    на пример имот, фарма или бизнис информација, вашата
                    локација, и други демографски информации.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Информации за Трансакција на кој начин стапувате во контакт
                    со нас, нашите дистрибутери и бизнис партнери, вклучувајки
                    ги и набавките, документи за опремата, и кориснички сметки
                    кои ни помагаат да ја подобриме корисничката подршка, како и
                    да Ви понудиме намаленија и промоции.
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Информации за Корисничката Сметка која е потребна за да се
                    заврши вашата набавка, како податоци за компанијата,
                    податоци од данок на додадена вредност, правни застапници
                    итн.
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Информации за Корисничка Сметка, кои се внесуваат во
                    софверот за да може да се користи истиот како што се
                    електронска сметка, локација на опремата, податоци од
                    областа, подесувања итн.
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Информации за Мобилен Уред - за да можете да го користите
                    софтверот на мобилни уреди како што се мобилни телефони и
                    таблети, во зависност од уредот и карактеристиките на
                    операцискиот систем, можеби ќе треба да овозможите пристап
                    до:
                    <br />
                    Приближна локација (врз основа на мрежата);
                    <br />
                    Точна локација (врз основа на ГПС и мрежа);
                    <br />
                    Камера - слики и видеа;
                    <br />
                    Преглед на мрежна поврзаност;
                    <br />
                    Контролна светилка;
                    <br />
                    Целосен пристап на мрежа.
                    <br />
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Вие би имале целосен пристап на Личните Податоци кои се
                    чуваат во софтверот за Вас. Ние ги превземаме разумните
                    чекори за да ви овозможиме да поправите, дополните или
                    избришете информација која се покажала како неточна или
                    нецелосна. Имате право да ги поправите информациите или да
                    додадете нови датотеки вие самите. Вашата електронска адреса
                    е внесена за да се овозможи контакт и да се потврди
                    идентитетот на вашата корисничка сметка во софтверот.
                    Безбедно пристапување кон постапката е овозможено со шифра
                    (пасворд).
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <p className="fw-semibold fs-6 text-gray-600">
              Податоци за опремата се податоци кои се создаваат со користење или
              се приберени од опремата која сте ја купиле од нас, а кои се
              чуваат на нашите сервери. Податоци за опремата вклучуваат:
            </p>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Количина на врнежи;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Температура на почвата;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Влажност на почвата;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Брзина на ветерот;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Температура на воздухот;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Просечна влажност на воздухот;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Атмосферски притисок;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Насока на ветерот;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Влажност на листот;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Датум и време;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Волтажа на батеријата;
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <span className="bullet me-3"></span>

              <div className="text-gray-600 fw-semibold fs-6">
                Волтажа на соларните панели;
              </div>
            </div>

            <p className="fw-semibold fs-6 text-gray-600 mt-5">
              Со цел да се добие целосна искористеност од опремата и софтверот,
              можеби ќе треба да споделите Податоци за Опремата кои се
              однесуваат на територијалната покриеност на вашата опрема во
              рамките на општа агрикултурна временска прогноза во согласност со
              правилата и условите кои се наведени во Правила на Користење.
              <br />
              <br />
              Ние може да ги обработиме и/или зачуваме податоците од надворешни
              системи (како што се Гугл Мапи, канали за временска прогноза,
              степен на раст кај растенијата и пропишан модел на болести, итн)
              или да ги поврземе податоците со цел да се обезбедат услугите кои
              ги барате.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Како се прибираат информациите и податоците
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Директно од Вас- ние ги собираме информациите директно од Вас. Ќе
              бидете прашани за Лични Податоци (пр. Контакт Информации и Бизнис
              Информации) кога ќе воспоставите контакт со нас, како на пример
              кога ќе се регистрирате на нашиот сајт, да се пријавите за
              добивање писма, или кога ќе направите нарачка. Ние може да
              прибереме дополнителни Бизнис Информации од трети лица кои
              доставуваат податоци кои ќе ни помогнат да ја подобриме нашата
              датотека и ќе ни помогнат подобро да ги разбереме нашите
              потрошувачи, или од социјалните мрежи како што се Фејсбук, ако
              имате ставено “ми се допаѓа„ на нашата страна или сте се вклучиле
              во нашата мрежа. Исто така, прибираме Лични Податоци кога Вие ги
              користите нашите мобилни апликации или кога ќе не контактирате,
              кои се користат со цел за подобра грижа за корисници.
              <br />
              <br />
              Од нашите Подружници, Дистрибутери и други Бизнис Партнери - кога
              ќе купите или земете под закуп производ или услуга, ние прибираме
              Лични Податоци (на пр. Информации за Трансакција и Информации за
              Финансиска Сметка) од нашите дистрибутери и финансиски подружници.
              Исто така, Лични Податоци може да прибереме од трети лица ( како
              што е Агенцијата за Трговски Регистар) со цел да се заврши Вашата
              набавка.
              <br />
              <br />
              Од нашата веб-страна - ако воспоставите контакт со нас онлајн, ние
              користиме колачиња и други технолошки алатки за прибирање
              информации за Вашиот компјутер и Вашето користење на нашата
              веб-страна и нашите апликации. Ови информации се третираат како
              Лични Податоци кога се поврзани со Вашата Контакт Информација. За
              повеќе информации за колачињата и другите технологии, Ве молиме
              разгледајте го делот Колачиња и Слични Технологии за Прибирање
              Информации подолу.
              <br />
              <br />
              Од Вашиот мобилен уред(и) - ние може да ја добиеме Вашата локација
              од Вашиот мобилен уред или други уреди кои се гео-свесни. Овие
              информации се третираат како Лични Податоци, ако идентификува или
              распознава човечко лице.
              <br />
              <br />
              Од Вашата опрема - ние прибираме Податоци за Опрема од Вашата
              опрема преку безжична технологија за пренос на податоци. Ова не
              спаѓа под Лични Податоци.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Ваша Согласност
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Со купување на нашите производи и услуги и/или користење на нашите
              веб страни, Вие јасно се согласувате за ние да можеме да ги
              прибереме, користиме и споделиме Вашите лични податоци во
              согласност со оваа Политика за Приватност. Ако не се согласувате
              со прибирањето, користењето и споделувањето на Вашите податоци на
              овој начин, Ве молиме не ги купувајте нашите производи, не ги
              користете нашите услуги и/или нашата веб страна или на било каков
              друг начин на кој може да ни дадете Ваши лични податоци.
              <br />
              <br />
              Ние само ги прибираме, користиме и споделуваме Вашите лични
              податоци само онаму каде се согласувате и каде законски е
              наложено. Може да Ви побараме дополнителна согласност, ако треба
              да ги искористиме Вашите податоци за цели што не се наведени во
              оваа Политика на Приватност. Вие не сте никако приморани да ни
              дадете ваква согласност, но ако одлучите дека нема да дадете
              согласност, тогаш Вашето учество во одредени активности може да
              биде ограничено. Ако пак се одлучите да дадете дополнителна
              согласност, условите на таа согласност ќе преовладаат во случај на
              било каков конфликт со условите од оваа Политика на Приватност.
              <br />
              <br />
              Ве молиме забележете, иако ние може да ги обработиме Вашите лични
              податоци без Ваша дозвола, ако имаме законска причина за тоа,
              таквата употреба нема да ги загрози Вашите права, слободи и
              интереси. Било каква употреба од тој вид би била во рамките на
              оваа Политика на Приватност.
              <br />
              <br />
              Вие можете да ја повлечете Вашата согласност за користење на
              вашите лични податоци во било кое време по пат на електронска
              пошта или со воспоставување на контакт на адресата запишана
              погоре. Повлекувањето на Вашата согласност не влијае на
              законодавноста при било која процедура што се случила пред тоа
              повлекување.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Како ги користиме Информациите и Податоците („Намените“)
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Личните Податоци и Податоците за Опремата ги користиме за повеќе
              намени, имено:
            </p>

            <div className="mb-8">
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Подршка за Корисници: Продавачот е овластен да пристапи кон
                    сет од податоци кои се познати на потрошувачот за да се
                    овозможи услуга дефинирана според договорот, да се исполнат
                    нарачки за производи или услуги, и за други услуги поврзани
                    за Вашата нарачка, како што е доставата на производот,
                    негово одржување, гаранција, изнајмување итн.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Техничка подршка: да овозможи подршка, вклучувајки
                    надгледување и управување на достапноста на производите кои
                    се купени, да се направат надрадби и подобрувања на
                    производите, програмите за комуникација, за дијагностицирање
                    и поправки, безбедност на производот и повелкување од пазар;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Маркетинг: да ви понудиме комуникација и понуди за производи
                    и услуги од нас или од нашите доставувачи или партнери;
                    вклучувајќи понуди кои се темелат на Вашите интереси, од
                    личен или бизнис карактер и локација.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Истражувања и Промотивни Настани: да се воспостават
                    истражувања, анкети, обложувања, натпревари, програми за
                    лојалност и други промотивни настани;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Квалификуваност: да се одреди дали сте квалификувани за
                    одредени производи, услуги и понуди;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Комуникација: да Ви понудиме информации кои се од Ваш
                    интерес, како што се информации во врска со производот,
                    списанија од копанијата, каталози, објави, потсетници и
                    летоци од технички карактер; и
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Справување со Секојдневните Потреби на Бизнисот: како што се
                    уплатите и управување со финансиските сметки, развој на
                    производот, безбедност на производот, управување со
                    договори, администрација на веб страната, управување со
                    веб-форумите, нарачки и исполнување на договори, аналитика,
                    спречување на измами, колективна управа, пријавување и
                    усогласување со закон.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Зошто ги Споделуваме Информациите и Податоците
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Ние го ограничуваме споделувањето на Личните Податоци и Податоци
              за Опремата со тоа што:
            </p>
            <div className="mb-8">
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Ние би споделиле Лични Податоци на надворечни трети лица
                    кога веруваме со најдобри намери дека споделувањето е
                    разумно потребно за (а) да се заштити безбедноста на било
                    кое лице од смрт или сериозна телесна повреда; (б) да се
                    спречи измама или злоупотреба против нас од страна на нашите
                    корисници; (в) да се одбраниме ние и нашите подружници или
                    вработени од било каква легална процедура која произлегува
                    од податоците поврзани со нашите корисници; (д) други случаи
                    каде се наложува со закон.
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Ние може да ги споделиме Личните Податоци на нашите бизнис
                    партнери и/или провајдери на услуги кои соработуваат со нас
                    и не можат самостојно да ги искористат информациите кои им
                    ги споделуваме со цел да се изврши надворешна ревизија,
                    усогласување и корпоративни управни функции. На вакви трети
                    лица им се наложува да ги користат податоците за цели за кои
                    се споделени информациите, а се во согласност со оваа
                    Политика на Приватност, како и во согласност со законите за
                    кои се однесува. Вие може да не се согласите на ова
                    споделување на информации, но во тој случај ние нема да
                    бидеме во можност да ги доставиме нашите услуги во целост.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Исто така ние ги споделуваме Личните Податоци кога од нас
                    тоа се бара со закон, како одговор на судски налог, како и
                    на служби за спроведување на законот и судови во земјите со
                    кои соработуваме.
                    <br /> Ние би ги споделиле Личните Податоци на трети лица со
                    цел да се овозможи услугата, која Вие ја барате, како
                    податоци за соседна опрема, известување за ризик од болести,
                    итн.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Ако сте во фаза на интеграција, стекнување или продажба на
                    дел или во целост на вашите средства, ќе бидете известени
                    и/или ќе има известување на нашата веб-страна на било која
                    промена на сопственоста или на употребата на Личните
                    Податоци, како и било кој избор што можеби би се однесувал
                    на информација од ваков карактер.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Ако сте во фаза на интеграција, стекнување или продажба на
                    дел или во целост на вашите средства, ќе бидете известени
                    и/или ќе има известување на нашата веб-страна на било која
                    промена на сопственоста или на употребата на Личните
                    Податоци, како и било кој избор што можеби би се однесувал
                    на информација од ваков карактер.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Колачиња и Слични Технологии за Прибирање
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Кога ќе го посетите нашата веб-страна, или ги користите нашите
              мобилни апликации, ние прибираме одредени информации по автоматски
              начин, користејќи технологии како што се колачиња, пиксел тагови,
              алатки за анализирање на пребарувач, податоци за логирање и веб
              сигнал.
              <br />
              <br />
              На пример, кога ќе ја посетите нашата веб-страна, ние поставуваме
              колачиња на Вашиот компјутер. Колачињата претставуваат мали текст
              датотеки кои веб-страните ги праќаат на Вашиот компјутер или на
              други уреди поврзани со интернет за да може да го идентификува
              посебно Вашиот пребарувач и да ја складира информацијата или
              поставките на Вашиот пребарувач. Колачињата ни дозволуваат да Ве
              препознаеме кога ќе се вратите повторно. Тие исто така ни
              овозможуваат прилагодено искуство и ни овозможува на нас да
              забележиме одредени видови на измами.
              <br />
              <br />
              Пиксел таговите и веб сигналите се мали графички слики кои се
              поставени на веб-страните или во нашите електронски пошти кои ни
              овозможуваат да одредиме дали Вие имате превземено одредено
              дејство. Кога ќе добиете пристап до овие страни, или отворите или
              кликнете на електронска пошта, пиксел таговите и веб сигналите
              прават белег на тоа дејство. Овие алатки ни овозможуваат да го
              измериме одговорот на нашите разговори и да ги подобриме нашите
              веб- страни и промоции.
              <br />
              <br />
              Ние прибираме многу различни видови на информации од колачињата и
              други технологии. На пример, може да добиеме информација за уредот
              со кој пристапувате на нашата веб-страна, видот на Вашиот
              оперативен систем, видот на пребарувачот, доменот и други
              системски подесувања, како и јазикот кој го користи Вашиот систем,
              земјата и временската зона во која се наоѓа вашиот уред. Нашата
              листа на приклучување исто така може да забележи IP адресата или
              дериват од уредот кој Вие го користите за да се поврзете со
              интернет. IP адресата е единствен идентификувач што уредите го
              користат за да се препознаат и комуницираат еден со друг на
              Интернет. Исто така може да прибереме податоци за веб-страните што
              сте ги посетиле пред да се поврзете на нашата, како и на
              веб-страните што ќе ги посетите после тоа.
              <br />
              <br />
              Во некои случаи, ние ги поврзуваме информациите што се приберени
              со користење на колачиња и други технологии со Вашите Лични
              Податоци. Дополнително на тоа, ако поставките на Вашиот гео-
              свесен мобилен уред им овозможуваат на нашите мобилни апликации да
              прибираат информации за локацијата, тие ќе бидат приберени
              автоматски. Оваа Политика за Приватност одредува како ние ќе ги
              користиме информациите кога ќе ги поврземе со Вашите Лични
              Податоци.
              <br />
              <br />
              Продавачот е поврзан со компании за рекламирање како трети лица за
              да извршат фукнции за следење и пријавување на нашето веб
              обработување. Овие маркетинг компании како трети лица може да
              постават колачиња на Вашиот компјутер за да можат да ги достават
              таргетираните реклами до Вас. Овие маркетинг компании како трети
              лица не треба да прибираат Лични Податоци во овој процес, и ние не
              им даваме Лични Податоци во текот на овој процес.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Функција Кажи на Пријател
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Ние нудиме функција „кажи на пријател“ на нашата веб- страна. Ако
              изберете да ја користите оваа функција, ние автоматски испраќаме
              на Вашиот пријател електронска пошта само еднаш која ги содржи
              информациите кои Вие ги одредивте и го покануваме да ја посети
              веб-страната. Оваа информација се користи само еднаш и нејзината
              основна улога е да се испрати таа електронска пошта само еднаш и
              ние не ги задржуваме информациите.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Вашиот избор</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Вие може да ги ограничите информациите кои ќе ни ги доставите.
              Исто така, може да ја ограничите комуникацијата која ја
              воспоставуваме. За да ги откажете рекламните пораки, може да ја
              посетите нашата веб-страна и да го искажете Вашиот избор на
              контактот на кој се однесува. Од друга страна, исто така може да
              ги откажете идните маркетинг пораки со кликање на копчето за
              „Откажи“ најдолу на рекламните пораки кои Ви ги праќаме.
              <br />
              <br />
              Ве молиме забележете дека дури и да го откажете примањето на
              рекламен материјал, можеби ние ќе мора да ве контактираме со
              некоја важна Трансакциска Информација што се однесува на Вашата
              сметка или опрема. На пример, дури и да ги откажете рекламните
              пораки, ние секако ќе ви испратиме потврда кога ќе купите нешто од
              нашата веб-страна како и комуникација во врска со безбедноста на
              производот.
              <br />
              <br />
              Вие може да ги управувате со поставките на колачињата и да ги
              откажете како и други слични технологии на прибирање кои се
              користат со подесување на поставките на Вашиот пребарувач или
              интерфејс. Сите пребарувачи и интерфејси се различни, па ако
              отворите на делот за „помош“ на Вашиот пребарувач или интерфејс да
              ги научите предностите на колачињата како и другите подесувања за
              приватност што можеби се достапни.
              <br />
              <br />
              Вие одлучувате како Вашиот мобилен уред и мобилен пребарувач ја
              споделуваат информацијата во врска со Вашата локација, како и тоа
              како Вашиот мобилен пребарувач и сличните технологии се справува
              со колачињата со подесување на приватноста на Вашиот мобилен уред
              и безбедносните поставки. Ве молиме, навратете се на инструкциите
              кои Ви се наложени од провајдерот на Вашиот мобилен оператор или
              на производителот на вашиот уред за да научите како да ги подесите
              Вашите поставки.
              <br />
              <br />
              Исто така, може да се отпишете од споделувањето на Податоци за
              Опремата. За да го сторите тоа, треба да купите претплата без
              споделување на податоци. Во тој случај ќе Ви поставиме со
              специјално наменет пристап за Вашите Податоци од Опремата. Во
              случај да имате некакви прашања за Вашиот избор или ако Ви е
              потребна помош со откажувањето, Ве молиме котактирајте ја нашата
              Грижа за Корисници. Исто така, може да ни пишете на горе дадената
              адреса. Ако ни пратите писмо, Ве молиме обезбедете не со Вашето
              име, адреса, електронска пошта, како и информации во врска со
              комуникација која не сакате да ја добивате.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Пристап и Промена
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Продавачот е во согласност на применето право кога се однесува за
              Вашата можност да пристапите, да ги промените или избришете Вашите
              Лични Податоци бесплатно. Ако имате сметка онлајн или преку
              мобилна апликација, во било кое време Вие можете да се приклучите
              кон Вашата сметка и да пристапите и да ги обновите некои
              информации кои Вие ни ги доставувате. Ако ви треба подршка за да
              ги обновите или избришете Вашите Лични Податоци, Ве молиме
              котактирајте не на адресата погоре. Ако ни испратите писмо, Ве
              молиме вклучете го и Вашето име, адреса, електронска пошта и
              детално објаснување за промената кој сакате да ја направите.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Информации и Безбедност на Податоци
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Продавачот има вметнато информација и програма за заштита на
              податоци која содржи административна, техничка и физичка контрола
              која е направена за да може да ги зачува Личните Податоци и
              Податоци за Опремата. На пример, ние користиме технологија за
              кодирање која е стандардна за индустријата за да ги обезбедиме
              Вашиот безбедносен код и други чевствителни Лични Податоци.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Чување на Податоци и политика на задржување
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Вашите податоци се чуваат на нашите сервери(сервери) лоцирани во
              центри за податоци на ниво на претпријатие во Бугарија, на кои се
              однесуваат Политиките за приватност, договори за необјавување и
              обврзувачки договори. Доколку и се дури Вие не побарате
              отстранување на нашиот пристап и употреба на Личните Податоци, ние
              ќе ги чуваме податоците, со оглед на тоа дека таквото складирање
              на податоци е во склад со сите закони и регулативи на судската
              надлежност каде податоците се зачувани. Личните Податоци се чуваат
              само онолку колку е потребно во склад со потребите за кои истите
              биле приберени, или за кои понатаму ќе бидат разработени. Ако
              некој период на задржување на податоците е дефиниран во Условите
              за Работа за да се користи нашата опрема, софтвер или услуги или
              во договорот со Вас, ние ќе ги избришеме податоците кои се
              зачувани подолго од периодот на задржување на со цел да се задржи
              чувањето на податоци во согласност со сите закони и регулативи на
              кои подлежи во судската надлежност каде се чуваат податоците. Вие
              признавате и се согласувате дека тие податоци, штом еднаш ќе се
              избришат од Серверот, не можат да се повратат или да се создадат
              одново.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Пренос на Информации и Податоци во Други Земји
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Проинтегра со седиште во Бугарија и нејзините подружници,
              дистрибутери и партнери се наоѓаат во повеќе земји низ светот.
              Личните Податоци и Податоците за Опрема може да бидат прегледани
              или пренесени во Бугарија или до нашите процесори на податоци(
              како што се подружници, дистрибутери, партнери итн) насекаде низ
              светот за Намени наведени во оваа Политика за Приватност. Кога ќе
              пристапиме кон или ги пренесеме Личните Податоци и Податоци за
              Опремата го правиме тоа откако ќе добиеме Ваша согласност, а е во
              склад со применетото право. Дополнително, ги штитиме приватноста и
              безбедноста на Личните Податоци и Податоците за Опремата, без
              оглед на тоа каде се обработува или чува.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Политика на Приватност на Трети Лица
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Оваа Политика на Приватност се однесува само на употребата и
              споделувањето на информации и податоци од страна на Продавачот.
              Другите веб-страни и апликации кон кои може да се пристапат преку
              нас имаат свои правила и политики. Нашите самостојни дистрибутери,
              доставувачи и бизнис партнери имаат свои правила и политики исто
              така. Ве охрабруваме да се запознаете подобро со политиките за
              приватност кои се однесуваат на третите лица пред да им доставите
              информација или пред да искористите некоја понуда или промоција.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Приватност на Деца
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Веб-страната на Продавачот и апликациите не се наменети да
              прибираат Лични Податоци од деца. Ако мислите дека Вашето дете го
              користи нашата веб-страна, Ве молиме контактирајте не на адресата
              погоре за да може да истражиме и да ги избришеме несоодветните
              информации.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Форуми, Прегледи на Производот и Други Јавни Области
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Нашата веб-страна или страната на социјалните мрежи може да содржи
              форуми и други области каде ќе можете јавно да објавите преглед на
              производот, да комуницирате со други и да испратите медиумска
              содржина. Пред објавување во овие области, Ве молиме да ги
              прочитате Правилата за Користење внимателно. Немате никакви права
              на приватност при јавни објави. Сите информации кои ќе ги објавите
              ќе бидат достапни за сите со пристап до Интернет, а секој Личен
              Податок што ќе го вклучите во Вашата објава ќе може да биде
              прочитан, приберен и искористен од страна на други лица. На
              пример, ако ја објавите Вашата електронска пошта покрај преглед на
              производот, може да добивате несакани пораки. Ве молиме внимавајте
              кога објавувате било какви Лични Податоци.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Вработувања</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Ако имате аплицирано за вработување кај нас, Личните Податоци кои
              сте ги доставиле со Вашата апликација ќе бидат искористени за
              вработување и за други намени кои се бараат од страна на човечки
              ресурси.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Промени на оваа Политика за Приватност
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Од време на време, може да се надгради оваа Политика за Приватност
              за да може да се донесат нови или различни правила за приватност.
              Ние ќе поставиме известување на нашата домашна страна кога ќе
              направиме материјални промени на оваа Политика за Приватност.
              Дополнително, ако промените влијаат на начинот на кој ги користиме
              и споделуваме претходно прибраните Лични Податоци, ние исто така
              ќе Ве известиме за промените преку праќање на известување до Вас.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Заклучок</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Со внесувањето на Вашите Лични податоци во нашиот софтвер и
              веб-страна и со притискањето на копчето „Се Согласувам со
              Политиката за Приватност на Продавачот“, Вие прифаќате да можеме
              да прибереме, обработиме и, како во ограничен број на случаи кои
              се опишани погоре, да ги споделиме Вашите Лични Податоци и
              Податоци за Опремата на начин кој е опишан во оваа Политика за
              Приватност и нашите Услови за Користење.
            </p>
          </div>
          <div className="mb-8">
            <p className="fw-semibold fs-6 text-gray-800">
              Последно Обновено: 29 јануари 2023
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyMk;
