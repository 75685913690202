import React from "react";
import "../webview.style.css";

const AboutMk = () => {
  return (
    <div className="flex-lg-row-auto">
      <div className="card ">
        <div className="card-body">
          <div className="mb-7">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              „АГРИО“ - иновативно софтверско решение за земјоделците
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              АГРИО“ e софтвер на модел за прогноза на болести и мобилна
              апликација кои претставуваат надградба на агрометеоролошка станица
              и примена на сензори, Овој софтвер има за цел, целосно да им ја
              олесни работата, со зголемување на приносите и истовремено
              намалување на трошоците во производство.
            </p>

            <p className="fw-semibold fs-6 text-gray-600">
              Користење на сензори и метеоролошки станици, надградени со „АГРИО“
              како практична дигитална алатка, ќе им овозможи на индивидуалните
              земјоделци и поголемите производители на земјоделски производи,
              увид и следење на важни параметри за работењето, а со тоа ќе им
              помогне и во донесувањето на правилни одлуки за одредување на
              период на садење, превентивни мерки за заштита од болести и
              штетници, прецизно извршување на агротехнички мерки и сл. Исто
              така се мерат податоци за локацијата каде што е поставена
              станицата и која што е калибрирана според измерените податоци од
              самата станица. Во прогнозата на станицата се вклучени:
            </p>

            <div className="my-2">
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Температура на воздухот
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Релативна влажност на воздухот
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Количество на врнежи
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Атмосферски притисок
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Брзина и правец на ветер
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">Облачност</div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Магла (видливост)
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Точка на роса
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Услови за невреме
                </div>
              </div>

              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Појава на мраз
                </div>
              </div>
            </div>
          </div>

          <p className="fw-semibold fs-6 text-gray-600">
            Се ова придонесува за заштеда на репроматеријали, време и енергија
            како и значително намалување на трошоците и намалување на
            загадувањето на животната средина. Овие податоци се важни за секоја
            земјоделска култура и периодот на вегетација на истите. Тоа значи
            дека сите земјоделци ќе знаат точно колку вода изгубиле во почвата и
            на тој начин ќе знаат точно кога и колку да наводнуваат. Со тоа се
            заштедува неконтролирано трошење на вода, но и заштеда за измивање
            на хранливите материи во почвата.
          </p>
          <p className="fw-semibold fs-6 text-gray-600">
            „Агроптима“ е македонска компанија која со помош на поддршката од
            Фондот за иновации и технолошки развој, ја креираше дигиталната
            платформа „АГРИО“.
          </p>

          <p className="fw-semibold fs-6 text-gray-600">
            Како компанија „Агроптима“ е снабдувач на висококвалитетни течни
            ѓубрива со потекло од ЕУ и е тесно поврзана со земјоделското
            производство во Македонија, Бугарија, Србија и Албанија. Компанијата
            работи во насока на олеснување на работата на земјоделците,
            зголемување на приносите при истовремено намалување на трошоците во
            производство овозможувајќи и решенија за дигитализација на
            земјоделството.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMk;
