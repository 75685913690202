import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import en from "../locales/en/translations.json";
import mk from "../locales/mk/translations.json";
import sq from "../locales/sq/translations.json";
import sr from "../locales/sr/translations.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "mk",
    resources: {
      en: en,
      mk: mk,
      sq: sq,
      sr: sr,
    },
  });
export default i18n;
