import i18next from "i18next";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { filters } from "../../config/filter";
import { AlarmContext } from "../../context/alarm.context";
import { StationContext } from "../../context/station.context";
import { setActiveStation } from "../../store/stations/stations.action";

const NewAlarm = ({ station }) => {
  const { createNewAlarm } = useContext(AlarmContext);
  const stations = useSelector((state) => state.stations.stations);
  const { fetchStations } = useContext(StationContext);
  const { t } = useTranslation();

  useEffect(() => {
    stations.length === 0 && fetchStations();
  }, []);

  const [data, setData] = useState({
    filter: "",
    value: "",
    comparing: "",
    station: station,
  });

  const handleNewAlarm = () => {
    createNewAlarm(data);
  };

  const handleFilterChange = (e) => {
    setData({ ...data, filter: e.target.value });
  };

  const handleCompairingChange = (e) => {
    setData({ ...data, comparing: e.target.value });
  };

  const handleValueChange = (e) => {
    setData({ ...data, value: e.target.value });
  };

  const handleStationChange = (e) => {
    setData({ ...data, station: e.target.value });
  };

  return (
    <div className="card card-flush h-xl-100">
      <div className="card-header pt-7">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-dark">{t("newAlarm")}</span>
          <span className="text-gray-400 mt-1 fw-semibold fs-6">
            {t("addNewAlarm")}
          </span>
        </h3>
      </div>

      <div className="card-body pt-5">
        <div
          id="kt_table_widget_5_table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="table-responsive">
            <table
              className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer"
              id="kt_table_widget_5_table"
            >
              <thead>
                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  {!station ? <th>{t("singleStation")}</th> : ""}
                  <th className="">{t("filter")}</th>
                  <th className="text-center">{t("border")}</th>
                  <th className="text-end">{t("value")}</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  {!station ? (
                    <td>
                      <select
                        name=""
                        className="form-select form-select-solid fw-semibold select2-hidden-accessible fs-7 pe-8"
                        onChange={(e) => handleStationChange(e)}
                        defaultValue={""}
                      >
                        <option value="">{t("selectStation")}</option>
                        {stations &&
                          stations.map((value) => {
                            return (
                              <option key={value._id} value={value._id}>
                                {i18next.language === "mk" ||
                                i18next.language === "sr"
                                  ? value.cyrilicName
                                  : value.name}
                              </option>
                            );
                          })}
                      </select>
                    </td>
                  ) : (
                    ""
                  )}
                  <td>
                    <select
                      name=""
                      className="form-select form-select-solid fw-semibold select2-hidden-accessible fs-7 pe-8"
                      onChange={(e) => handleFilterChange(e)}
                      defaultValue={""}
                    >
                      <option value="" disabled>
                        {t("filter")}
                      </option>
                      {filters.map(({ value, name }) => {
                        return (
                          <option key={value} value={value}>
                            {t(value.replace("1", ""))}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td className="text-center">
                    <select
                      onChange={(e) => handleCompairingChange(e)}
                      className="form-select form-select-solid fw-semibold select2-hidden-accessible fs-7 pe-8"
                      defaultValue={""}
                    >
                      <option value="" disabled>
                        {t("border")}
                      </option>
                      <option value=">">{t("goesUp")} &#62;</option>
                      <option value="<">{t("goesDown")} &#60;</option>
                      <option value=">=">{t("moreOrEqual")} &#8805;</option>
                      <option value="<=">{t("lessOrEqual")} &#8804;</option>
                    </select>
                  </td>
                  <td className="text-end">
                    <input
                      onChange={(e) => handleValueChange(e)}
                      className="form-control form-control-solid"
                      value={data.value}
                      placeholder={"0"}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      onClick={handleNewAlarm}
                      className="btn btn-sm btn-primary align-self-center fw-bold"
                    >
                      {t("addAlarm")}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAlarm;
