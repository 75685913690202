import Page from "../components/page.component";
import TemperatureWidget from "../components/temperature-widget.component";
import WelcomeCard from "../components/welcome-card.component";
import StationsCard from "../components/stations/stations-card.component";
import Activity from "../components/activity.component";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setActiveStation } from "../store/stations/stations.action";
import { useContext } from "react";
import { StationContext } from "../context/station.context";
import { AlarmContext } from "../context/alarm.context";

const Home = () => {
  const stations = useSelector((state) => state.stations.stations);
  const user = useSelector((state) => state.user.userInformation);
  const dispatch = useDispatch();
  const { fetchStations } = useContext(StationContext);
  const { alarms, fetchUserAlarms, events, fetchEvents } =
    useContext(AlarmContext);

  useEffect(() => {
    stations.length === 0 && fetchStations();
    dispatch(setActiveStation(false));
    if (!events) fetchEvents();
    if (!alarms) fetchUserAlarms();
  }, []);

  return (
    <Page title="Почетна" className={"app-container"}>
      <div className="row">
        <div className="col-sm-12 col-lg-4">
          <div className="row g-5">
            <div className="col-sm-12">
              <WelcomeCard
                name={user.name}
                stanici={stations.length}
                alarmi={alarms?.length}
                smetki={0}
                nastani={events?.length}
              />
            </div>
            <div className="col-sm-12">
              <Activity />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-8">
          <StationsCard />
        </div>
      </div>
    </Page>
  );
};

export default Home;
