import React, { useState } from "react";
import Highcharts from "highcharts";
import HcMore from "highcharts/highcharts-more";
import ReactApexChart from "react-apexcharts";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

const RainfallCard = () => {
  HcMore(Highcharts);

  // get station information
  const station = useSelector((state) => state.stations.activeStation);

  // set chartData state
  const [chartData, setChartData] = useState([]);
  const [chartDate, setChartDate] = useState([]);
  const { t } = useTranslation();

  // when station get the information
  useEffect(() => {
    var usingData = [];
    var precipitation = 0;
    station &&
      Object.values(station?.data).map((data) => {
        precipitation = 0;
        data.map(
          (bigData) => (precipitation = precipitation + bigData.precipitation)
        );
        usingData = [precipitation, ...usingData];
      });
    var dates = [];
    station &&
      Object.keys(station?.data).map(
        (date) => (dates = [...dates, moment(date * 1000).format("ddd")])
      );
    setChartDate(dates);
    setChartData(usingData);
  }, [station]);

  const rainfallChart = {
    series: [
      {
        name: t("rains"),
        data: chartData,
      },
    ],

    options: {
      padding: {
        top: 0,
        right: -10,
        bottom: -30,
        left: -10,
      },
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        offsetY: 20,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Highcharts.numberFormat(val, 2) + "l/m";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#000"],
        },
      },

      xaxis: {
        categories: chartDate,
        position: "top",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "l/m2";
          },
        },
      },

      grid: {
        borderColor: "#ddd",
        strokeDashArray: 4,
        padding: {
          top: 0,
          right: -10,
          bottom: -20,
          left: -10,
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      title: {
        enabled: false,
      },
    },
  };

  return (
    <div className="card card-flush overflow-hidden">
      <div className="card-header pt-7">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-800">{t("rains")}</span>
          <span className="text-gray-400 mt-1 fw-semibold fs-6">
            {t("rainMeasure")}
          </span>
        </h3>
      </div>
      <div className="card-body px-0 pt-0 pb-0 w-100">
        <ReactApexChart
          options={rainfallChart.options}
          series={rainfallChart.series}
          type="bar"
          height={170}
        />
      </div>
    </div>
  );
};

export default RainfallCard;
