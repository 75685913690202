import React from "react";

const Page = ({ children, className }) => {
  return (
    <div
      className={`container-xxl app-container-fit-mobile d-flex align-items-stretch mb-10 ${className}`}
      id="kt_content"
    >
      <div id="kt_content_container" className="container-xxl">
        {children}
      </div>
    </div>
  );
};

export default Page;
