import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoaderContext } from "../../context/loader.context";
import StationsTable from "./stations-table.component";

const StationsCard = () => {
  const { loader } = useContext(LoaderContext);
  const stations = useSelector((state) => state.stations.stations);
  const { t } = useTranslation();

  return (
    <div className="card card-flush h-lg-100">
      <div className="card-header pt-7">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-800">
            {t("availableStations")}
          </span>
          <span className="text-gray-400 mt-1 fw-semibold fs-6">
            {t("total") + " " + stations.length + " " + t("stations")}
          </span>
        </h3>
      </div>

      <div className={`card-body pt-6 ${loader ? "loader" : ""}`}>
        <div className="table-responsive">
          <StationsTable />
        </div>
      </div>
    </div>
  );
};

export default StationsCard;
