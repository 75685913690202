import React from "react";
import "../webview.style.css";

const TermsAndConditionsMk = () => {
  return (
    <div className="flex-lg-row-auto">
      <div className="card ">
        <div className="card-body">
          <div className="mb-7">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              ПРАВИЛА И УСЛОВИ <br />
              ОПШТИ ПРАВИЛА И УСЛОВИ
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              За опремата, софтверот и услугите на АРГИО за Меѓународни Клиенти
            </p>

            <p className="fw-semibold fs-6 text-gray-600">
              Продавачот набавува Опрема, и обезбедува Софтвер и Услуги под
              заштитениот знак на АГРИО на Клиенти под условите кои се опишани
              во овие Општи Правила и Услови, за Опремата, за Софтверот и
              Услугите на Агрио за меѓународни клиенти (одовде па натаму
              „Услови“). Овие услови се однесуваат само на клиенти и набавки кои
              не се на територијата на Северна Македонија. Во случај ако сте
              потрошувач, Ве молиме контактирајте не на{" "}
              <a href="mailto:info@agrio.mk">info@agrio.mk</a> и ќе Ви го дадеме
              нашиот предлог за индивидуалци. Условите се договор помеѓу
              Продавачот и Клиентот, кој ја има купено Опремата или Услугата
              директно од Купувачот, како што е опишано подолу. За Клиентите кои
              имаат купено Опрема или Услуги од дистрибутер на Продавачот, се
              применуваат различни услови.
              <br />
              <br />
              Ве молиме читајте ги Условите внимателно и ако се согласувате со
              нив, потврдете ја Вашата согласност со кликање на „Ги прифаќам
              Општите Правила и Услови“ копчето на Софтверот. По потврдување на
              Вашата согласност, ние ќе сфатиме дека сте запознати и ги
              прифаќате Условите и се согласувате да се придржувате.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Дефиниции</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Опрема- временски станици Агрио, вклучувајќи ги и сите делови,
              резервни делови и додатоци за нив,
              <br />
              Софтвер- мобилната аликација на Агрио, што е достапна на Google
              Play или Apple AppStore;
              <br />
              Услуги - услугите, кои се однесуваат на Опремата и/или Софтверот,
              како што се податоци сега или во минатото, агрономски индикатори,
              временска прогноза, аларми, обнови на софтверот, техничка подршка
              итн.;
              <br />
              Клиент- бизнис клиентот, како индивидуа контактирана преку
              електронска пошта, е внесен во Софтверот, има набавено Опрема,
              Софтвер или Услуги директно од Продавачот и ги користи истите
              надвор од територијата на Северна Македонија за неговите бизнис
              активности, а не за да препродава, изнајмува или дава под наем или
              слични активности;
              <br />
              Продавач - АГРОПТИМА, со регистрирана адреса и седиште на ул. 15
              Корпус 2, Кисела Вода 1000 Скопје, ИД на компанијата: 7270992,
              ЕДБ: МК4043018524509, телефон: +389 78 294 192, електронска
              сметка: agroskopje@gmail.com.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Предмет</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Сегашните Услови ја регулираат:
            </p>

            <div className="mb-8">
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Набавки на Опрема;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Сервис за Опремата со и без гаранција;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Права за софтверско користење;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Пренос и чување на Податоци за Опремата;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Техничка подршка и Софтверско обновување
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Спецификација на Опремата
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Набавената Опрема подлежи на технички спецификации, кои се опишани
              на agrio.mk
              <br /> Одредената конфигурација на Опремата, која е доставена на
              Клиентот, е опишана во документите за продажба; Целата Опрема е
              ново произведена, целосно функционална и ги има поминато сите
              проверки за контрола на квалитет пред да биде доставена.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Достава</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Преносот, ракувањето и трошоците за осигурување се на сметка на
              Клиентот;
              <br />
              Продавачот може да предложи метод за испраќање, транспорт или
              осигурителна компанија, наведувајќи ги цените за транспорт и
              осигурување како и времето за достава;
              <br />
              Продавачот гарантира дека пакувањето, товарењето, како и
              предложениот метод на испраќање се со соодветни стандарди, кои
              осигуруваат дека Опремата ќе може да се очекува да стигне кај
              Клиентот во квалитетна состојба, со оглед на тоа дека транспортот
              и ракувањето на пакетот се надгледуваат;
              <br />
              Ако Клиентот не го одобрува предложениот метод на испраќање,
              транспорт или трошоците за осигурување, тој може сам да го
              организира испраќањето и осигурувањето;
              <br />
              Продавачот се согласува да го надгледува правилното испраќање на
              сите потребни документи (на пр.фактура, листа за пакувањето, CMR,
              итн.)
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Превземање</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              По сметката за Опремата, Клиентот мора да провери за било какво
              видливо оштетување:
              <br />
              <br />
              Ако Клиентот не ја провери Опремата или не најде никакви дефекти,
              Опремата се смета дека е одобрена и превземена, освен за некои
              подоцнежни дефекти ( оние кои не можат да се откријат при визуелен
              преглед). Ако Клиентот најде било какви дефекти по сметката за
              Опремата, тој мора веднаш да го извести Продавачот.
              <br />
              Продавачот не може да биде одговорен за дефекти што се случиле при
              транспорт, складирање и ракување со Опремата, за кое Клиентот може
              да земе оштета или во случај да нема осигурување треба да му плати
              на Продавачот за поправка.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Гаранција</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Периодот на гаранција за Опремата е 24 месеци од датумот на
              фактурата за купување, освен ако не е овозможено различно;
              <br />
              Гаранцијата ги покрива случаите при употреба, кои се опишани во
              Упатството за Употреба, кое е објавено на agrio.mk;
              <br />
              Гаранцијата не ги покрива дефектите кои настанале при несоодветно
              складирање, транспорт, пакување, ракување, инсталирање, работење,
              одржување или применување; погрешно користење, злоупотреба,
              модификација; инсталација на резервни делови или додатоци, кои не
              се купени од Продавачот; нелегална употреба, намерна штета,
              природен феномен или непогода; штета од животни; вандализам;
              кражба, како и други штети кои не се по вина на Продавачот.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Сервис под гаранција
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Во случај на дефект на Опремата, Клиентот мора да испрати сервисно
              барање, вклучувајќи и слики од расипаниот дел и неговиот сериски
              број, на <a href="mailto:tech@agrio.mk">tech@agrio.mk</a>.
              <br />
              Во време од 3 работни дена од сервисното барање, Продавачот треба
              да го извести Клиентот дали дефектот е покриен со гаранција или
              не.
              <br />
              Ако е покриен со гаранција, Продавачот треба да го санира дефектот
              од далечина, ако е технички возможно. Ако не е технички возможно,
              Продавачот треба или да испрати нов дел од истиот модел, или да му
              каже на Клиентот да го расклопи расипаниот дел, како што е опишано
              во Упатството за Употреба, и да го прати назад до Продавачот во
              неговото оригинално пакување. Продавачот треба да го поправи делот
              во рок од 20 дена по добивањето на сметката и да го врати назад до
              Клиентот.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Сервис без гаранција
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Ако дефектот на Опремата не е покриен со гаранција, или се случил
              по гарантниот период, Клиентот може да го искористи сервисот без
              гаранција. Продавачот врши поправки без гаранција, по известување
              на Клиентот за цената на поправката и по претходно плаќање од
              страна на Клиентот. Превозот, ракувањето и трошоците за
              осигурување се на сметка на Клиентот.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Софтвер Права на Користење
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Според овие Услови, Продавачот му дава на Клиентот неекслузивен,
              без можност за пренос договор со ограничени права да го користи
              Софтверот за одреден период, кој е опишан во овие Услови, со
              овозможување на корисничка сметка за да пристапи до Софтверот.
              <br />
              Сите функционалности на Софтверот се опишани на agrio.mk.
              <br /> Клиентот може да користи една корисничка сметка за три
              уреди.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Услови за Цени и Плаќање
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Продавачот ги наведува цените за Опремата и/или Услуги на
              agrio.mk.
              <br />
              Клиентот плаќа за Опремата и/или Услугите со метод на плаќање кој
              е наведен од страна на Продавачот на документите за продажба.
              <br />
              Цената е еднаква на нето сумата која треба да се плати на
              Продавачот од страна на Клиентот. Било кои даноци на продажба,
              обврски, налози од банката, трошоци за пренос на пари и други
              трошоци кои може да бидат поврзани со трансакцијата или поради
              територијата на која се наоѓа Клиентот, ќе бидат на сметка на
              Клиентот.
              <br />
              Продавачот се согласува да го надгледува правилното поднесување на
              сите потребни документи за пренос (на пр. Фактура, листа на
              пакување, CMR, итн.) etc.)
              <br />
              Продавачот го задржува правото за промена на цените од време на
              време со објавување на новите цени на agrio.mk.
              <br />
              Секоја промена на цената на претходно платена Услуга не влијае на
              правата на Клиентите кои веќе имаат платено за таква Услуга.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Ред. Заклучување на Договорот
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Клиентот може да направи нарачка за да купи Опрема или Услуги од
              Продавачот преку електронска нарачка од страната на
              agrio.mk(отсега па натаму „ Формулар за Електронска нарачка“) или
              со прифаќање на профактура од овластен представник на Продавачот.
              За да се направи нарачка, Клиентот треба да даде име, податоци за
              фактура, адреса за испорака и електронска пошта за контакт. Со
              кликање на „Нарачај“ копчето или со плаќање на профактурата
              Клиентот го потврдува видот и количината на артиклите кои ги
              нарачува и ги прифаќа Условите. Во случај на нарачка во форма од
              Електронска нарачка, Продавачот испраќа профактура, која ги содржи
              нарачаните артикли, цената на секој, количина, попуст (ако е
              применет), време на достава и начин на плаќање.
              <br />
              Времето на достава почнува од датумот на плаќањето на сметката.
              <br />
              Со кликање на „Се согласувам со Општите Правила и Услови“ копчето
              и/или со кликање на копчето „Нарачка“, Клиентот прави електронска
              изјава која според Бугарскиот Електронски Документ и Електронското
              Потписничко Право, изјавува дека е запознат со овие Услови, ги
              прифаќа и се согласува да постапува според нив. Целиот текст на
              Условите е достапен на meteobot.com во форма која може да се симне
              и да се направи копија.
              <br />
              Пред кликање на копчето за „Нарачка“, Клиентот може слободно да ги
              смени податоците кои се внесени од негова страна во Електонската
              нарачка.
              <br />
              Веднаш по примањето на нарачката во форма на Електронска нарачка
              Продавачот потврдува дека ја добил нарачката и согласноста од
              Клиентот со праќање на потврдна порака на електронската пошта на
              Клиентот, заедно со документ за продажба( профактура). По потврата
              на нарачката од страна на Продавачот, се склучува договор меѓу
              Клиентот и Продавачот.
              <br />
              Јазикот на договорот е Англиски.
              <br />
              Со прифаќањето на ове Услови Клиентот се согласува да биде
              службено контактиран на адресата на електронската пошта која ја
              доставил. Изјава за спроведување на договорот и потврда за
              сметката се сметаат за добиени, кога имаме пристап до нивните
              адреси. Странка во договорот со Продавачот е Клиентот, според
              податоците кои се приложени со нарачката.
              <br />
              Овие Услови ги регулираат односите меѓу Клиентот и Продавачот,
              освен ако тие не наведат различно на писмено. Во случај на некое
              спротивнставување помеѓу Условите и било кој пишан договор, се
              применува пишаниот договор.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Пристап до Податоци за Опремата и Услуги
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Со цел да се користат податоци од Опремата и/или други Услуги,
              Клиентот треба да го симне Софтверот од Google Play или App Store,
              да го иснсталира на неговиот мобилен уред, и да ја внесе адресата
              на неговота електронска пошта. Ако Клиентот има Купено Опрема
              и/или Услуги, клиентот треба да го внесе серискиот број и/или ПИН
              шифра во Софтверот, кои се добиени со Опремата и/или Услугите.
              Клиентот има пристап до Податоците од Опремата и/или други Услуги;
              <br />
              Ако Клиентот го смени мобилниот уред, може да ја активира неговата
              корисничка сметка на нов уред со користење на истата адреса од
              електронската пошта.
              <br />
              Клиентот може да ги користи Податоците од Опремата бесплатно на
              дури три уреди за секој купен артикал на Опрема и/или Услуги на
              повеќе уреди.
              <br />
              Само клиентот е одговорен за заштита на неговата корисничка
              сметка, како и за сите дејства кои се превземени од негова страна
              или од трети лица со користење на таа сметка.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Споделување на Податоци
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Со купување на нов артикал на Опрема и претплата со споделување
              податоци и претплата со вклучено споделување на податоци Клиентот
              учествува во општата мрежа за земјоделство. Податоци од Опремата
              значи податоци кои се создадени од сензорите на Опремата, како што
              се; количината на врнежи, температура на почва, влажност на почва,
              брзина на ветер, насока на ветер, температура на воздух, влажност
              на воздух, воздушен притисок, влажност на лист, датум и време,
              волтажа на батеријата, волтажа на соларните панели. Податоците од
              Опремата не се лични податоци. Клиентот нема никакво право да ги
              споделува податоците од Опремата, која тој не ја поседува.
              <br />
              Со учество во временската мрежа на земјоделците и со споделување
              на Податоците на Опремата, Клиентот може да извлече максимални
              придобивки од Опремата и Софтверот, како што е користењето на
              соседна Опрема, известувања за распространување на болести итн.
              <br />
              Во случај да Клиентот купи претплата со споделување на податоци,
              тој се согласува, под овие Услови, дека Податоците од Опремата,
              како и базата на податоци, кои се изведени од нив, се ексклузивна
              сопсвеност на Продавачот. Авторските права на сите бази на
              податоци, кои се создадени со користење на Опремата припаѓаат на
              Продавачот. Продавачот има право да се ослободи од нив, да ги
              користи, направи копии или да ги сподели споделените Податоци од
              Опремата и базата на податоци, вклучувајќи и да ги предаде на
              трети лица, без да му должи надомест на Клиентот.
              <br />
              Во случај да Клиентот не сака да учествува во временската мрежа на
              земјоделците и не сака да ги дели Податоците од Опремата, тој може
              да избере претплата без споделување податоци. Со купување на таква
              претплата, Податоците од Опремата, кои се создадени за време на
              претплатата и изведената база на податоци, се во ексклузивна
              сопственост на Клиентот, а тој може да се ослободи од нив,
              користи, направи копии од неговата Опрема и изведената база на
              податоци, вклучувајќи и да ги предаде на трети лица, без да му
              должи надомест на Продавачот.
              <br />
              За да се избегне сомнеж, во случај да Клиентот продолжи да го
              користи Софтверот по истекувањето на претплатата без споделување
              на податоци, и не ја обнови претплатата, Податоците од Опремата и
              сите бази на податоци кои се изведени во периодот без претплата се
              во сопственост на Продавачот.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Техничка подршка
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Техничката подршка вклучува и совети за користење на Опремата,
              Софтверот и Услугите - преку телефон или електронска пошта - секој
              работен ден од 09:00 до 18:00, Источно Европско Време. <br />
              За Клиентите кои имаат преплата, Продавачот обезбедува техничка
              подршка без дополнителна наплата.
              <br />
              За Клиентите кои немаат купено претплата, Продавачот нуди техничка
              подршка по известувањето на Клиентот за цените и претходно плаќање
              од страна на Клиентот.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Пренос на Податоци
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              За одредени региони, кои се наведени во meteobot.com, Продавачот
              може да го снабди Клиентот со Опрема со инсталирана СИМ картичка
              за податоци и СМС за пренос на Податоци од Опремата од Опремата од
              серверите на Агрио.
              <br />
              Клиентот не смее да ја користи СИМ картичката за други намени,
              освен за опремата, купена од Продавачот. Ако ги прекрши овој
              услов, Продавачот го задржува правото да ја деактивира СИМ
              картичката, без да должи никаков надомест.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Пристап и Складирање на податоци
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Ако Клиентот се сложи, да со цел да ја користи Опремата, Софтверот
              и поврзани Услуги, Продавачот има пристап до податоците и може да
              ги чува и преработува. Детални информации за намената на
              скалдирањето, обработувањето и пристапот до Податоците од Опремата
              се вклучени во Политиката на Приватност. Клиентот може во било кое
              време да му го одбие правото на Продавачот да чува, обработува и
              пристапува кон податоците, со праќање на електронска пошта на
              <a href="mailto:info@agrio.mk">info@agrio.mk</a>. Во случај на
              вакво одбивање, Продавачот е ослободен од неговите обврски да
              овозможи функционален софтвер и услуги, кои бараат таков пристап
              на податоци, за обработување и чување.
              <br />
              Продавачот се согласува да чува податоци од Опремата на
              серверот(серверите), која е купена од Клиентот одреден временски
              период. Периодот зависи од тоа дали Клиентот има купено претплата
              или не. Со претплата податоците се чуваат минимум 10 години, а без
              претплата- 3 месеци.
              <br />
              Клиентот може да добива информации за зачуваните податоци во било
              кое време. Овој дел 16 не се однесува на Личните Податоци, кои ќе
              подлежат на правилата од Политиката на Приватност и сите закони за
              заштита на податоците.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Достапност на Софтверот
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Продавачот обезбедува достапност на Софтверот и серверите, каде се
              чуваат податоците, минимум 99% од времето, пресметано на месечна
              база. Во случај да Продавачот ја прекрши оваа обврска, Клиентот
              може да добие повраток на пари од претлатата, која е соодветна на
              траењето на немањето на Софтвер.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Надградба на Опремата и Софтверот
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Продавачот го надградува Софтверот со додавање на функционалности
              и/или решавање багови, ако се појават, минимум четири пати
              годишно. За време на ваквите надградби може да се појават мали
              прекини на Софтверот, за кои Продавачот ќе направи искрени напори
              да го извести Клиентот пред време. Овие прекини не ја попречуваат
              обврската на Продавачот да обезбеди достапност на Софтверот 99%од
              времето, кое е пресметано на месечна база.
              <br />
              Продавачот го задржува правото на промена и/или надградба на
              техничките спецификации за Опремата, Софтверот и/или Услугата,
              како и додавањето на нови карактеристики, со тоа што техничките
              спецификации на Опремата, Софтверот и/или Услугите се подобруваат,
              во однос на претходните.
              <br />
              Продавачот нема да мора да известува за решавањето на багови,
              поправки на некои грешки, или други измени на Опремата или
              Софтверот, во случај да;
              <br />
              Не влијаат на операбилноста;
              <br />
              Ќе ја подобрат безбедноста, или <br />
              Се наложени од некој закон, регулатива, владино тело, судски налог
              или други легални или договорни побарувања.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Претплати</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Почетната претплата со или без споделување на Податоци за Опремата
              не е вклучена во цената на новата Опрема и треба да се набави
              посебно. Цените на двата вида на претплата се наведени од страна
              на Продавачот.
              <br />
              По истекувањето на почетниот период на претплата, Клиентот може да
              ги обнови за друг период. Ако Клиентот не сака да ја обнови
              претплатата, може да користи некои Услуги и функционалности на
              Софтверот без претплата.
              <br />
              Претплатата не може да се префрли.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Производи и Услуги од Трети Лица
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Продавачот може да му овозможи на Клиентот производи од трети
              лица, како што е софтвер за мапирање, сателитски слики,
              геолокација, временска прогноза, известување за болести итн.
              <br />
              Производите и услугите на третите лица се овозможени само под
              условите и правилата на засегнатото трето лице.
              <br />
              Со овозможувањето на овие производи и/или услуги, Продавачот
              гарантира, дека ги има направено сите потребни тестирања да
              обезбеди висока веродостојност, достапност и изведба, но не
              подлежи на никаква одговорност за истите.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Инфраструктура</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              За да ги користите опремата, Софтверот и/или Услугите и да
              обезбедите нивно нормално оперирање според овие Услови, Клиентот
              се согласува да ја овозможи потребната инфраструктура и ресурси,
              вклучувајќи:
              <br />
              Соодветна локација и услови за да ја управува Опремата, според
              Упатството за Употреба;
              <br />
              Шипка ( на пр. Метален цилиндар) за инсталација на Опремата според
              Упатството за Употреба;
              <br />
              СИМ картичка за податоци со СМС услуги, доколку не е овозможена од
              Продавачот;
              <br />
              Достапна мрежна покриеност на местото на инсталација;
              <br />
              Мобилен уред со интернет конекција и оперативен систем Android,
              верзија 4.1 (или повисока) или iOS, верзија 8.0 (или повисока), на
              кој ќе го користи Софтверот;
              <br />
              Корисничка сметка на Google Play или App Store за да го симне и
              догради Софтверот.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Инсталација и Управување
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Клиентот се согласува да ја инсталира Опремата според Упатството
              за Користење, освен ако Клиентот и Продавачот не се договорат
              спротивно. Клиентот се согласува да ги запази барањата за
              складирање, пренос, пакување, ракување, инсталација, оперирање,
              одржување и применување, според Упатството за Користење.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Допис</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Клиентот треба да го извести Продавачот за било кои проблеми со
              оперирање што може да се појават со Опремата, Софтверот и/или
              Услугите на официјалните електронски и поштенски адреси на
              Продавачот. Продавачот ќе го извести Клиентот за електронската
              пошта, која е наведена од Клиентот при регистрацијата на
              СОфтверот.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Правата за интелектуална сопственост
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Правата за интелектуална сопственост ( наречени „IPправа“) се
              заштитени со закон, права на заштитени имиња, лого, патенти,
              корисни модели, права за изуми, регистрирани и нерегистрирани
              права на дизајн, авторски права и слични права, права на
              топографија на полу- проводник, бази на податоци, интернет домени,
              вештини, тајни на занаетот, права на тврдења на неправедна
              конкуренција, како и било какви други права на интелектуална
              сопственост во светот, без оглед на тоа дали е регистрирано или
              не, вклучувајќи ја и апликацијата за регистрација како такво право
              и правата за да пријави таква регистрација.
              <br />
              Заштитниот знак на Агрио, логото 1; Софтверот, вклучувајќи го и
              изворниот код, објектниот код, извршувачки фајлови, алгоритми,
              инфраструктура, технички спецификации, архитектура, база на
              податоци, графички интерфејс, вештини, IP права за Опремата
              вклучувајќи и дизајн, архитектура, полу-спроводлива топографија,
              фирмвар, како и компоненти на Софтверот и Опремата, не вклучувајќи
              ги деловите кои се во сопственост на трети лица, се во ексклузивна
              сопственост на Продавачот и тој ги поседува сите IP права на нив
              според применето право, вклучувајќи но не ограничувајќи го правото
              на продажба, копира, достави, да ја даде лиценцата на трети лица,
              користи, измени, развие, прилагоди или на било кој начин да се
              ослободи од, како и да даде права на трети лица да се ослободат ос
              Софтверот и Опремата.
              <br />
              Ниту Клиентот, ни било кое лице не може да измени, обратно да го
              конструира, да го расклопи, прилагоди, да има неовластен пристап,
              копира и на било кој начин прави копии или ја приспособи Софтверот
              и Опремата.
              <br />
              Во случај каде Клиентот или лицето поврзано со него ги нарушат
              IPправата на Продавачот, Клиентот должи парична казна во големина
              од пет пати од продажната вредност на Опремата, Софтверот или
              Услугата на која се однесува. Ова не го спречува Продавачот да
              бара дополнителна компензација, во случај на поголема штета.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Одговорности</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Софтверот, Опремата и Услугите се опремени како ШТО ИЗГЛЕДААТ и
              КАКО ШТО СЕ ДОСТАПНИ. За сите има кутии, куќишта кои одговараат на
              Софтверот, Опремата и Услугите, како пто е објаснето во Упатството
              за Употреба.
              <br />
              До степен кој е законски дозволен и СО ИСКЛУЧОК ШТО Е ЈАСНО
              НАВЕДЕН ВО ОВИЕ УСЛОВИ, ПРОДАВАЧОТ СЕ ОДРЕКУВА ОД СИТЕ ГАРАНЦИИ И
              УСЛОВИ, ИЗНЕСЕНО, КОЕ СЕ ПОДРАЗБИРА ИЛИ Е НАЛОЖЕНО ЗАКОНСКИ,
              ВКЛУЧУВАЈЌИ БЕЗ ОГРАНИЧУВАЊЕ НА ГАРАНЦИЈАТА НА СОПСТВЕНОСТ, БЕЗ
              КРШЕЊЕ НА ЗАКОНОТ, РЕКЛАМИРАЊЕТО И СПОСОБНОСТ ЗА ОДРЕДЕНА НАМЕНА.
              ПРОДАВАЧОТ НЕ ПРЕТСТАВУВА ИЛИ ГАРАНТИРА ДЕКА СИТЕ ПРОИЗВОДИ ЌЕ
              РАБОТАТ СИГУРНО И БЕЗ ПРЕКИНИ, ОСВЕН КАКО ШТО Е ЈАСНО НАВЕДЕНО ВО
              ОВИЕ УСЛОВИ. КЛИЕНТОТ ПРИФАЌА ДЕКА НЕ Е ВЛЕЗЕН ВО ОВОЈ ДОГОВОР СО
              ОЧЕКУВАЊЕ НА НЕКОЈА ГАРАНЦИЈА ИЛИ МОДЕЛ СО ИСКЛУЧОК НА ОНИЕ КОИ СЕ
              ЈАСНО НАВЕДЕНИ ВО ОВИЕ УСЛОВИ. НИТУ ЕДЕН СОВЕТ ИЛИ ИНФОРМАЦИЈА,
              КОЈ Е ДОБИЕН ПРЕКУ СОФТВЕРОТ ЌЕ СОЗДАДЕ НЕКОЈА ГАРАНЦИЈА КОЈА НЕ Е
              ЈАСНО НАВЕДЕНА ВО ОВИЕ УСЛОВИ.
              <br />
              Продавачот не е обврзан за НИКОЈА ДИРЕКТНА, ИНДИРЕКТНА, НЕНАМЕРНА
              или ПОСЛЕДИЧНА ШТЕТА, ВКЛУЧУВАЈЌИ И НЕОГРАНИЧУВАЈЌИ СЕ НА, ШТЕТА
              ЗА ЗАГУБА НА ПРОФИТ, ДЕЛОВНИ ВРСКИ, КОРИСТЕЊЕ, ИЛИ ДРУГИ
              НЕМАТЕРИЈАЛНИ ЗАГУБИ КОИ НАСТАНАЛЕ ОД: (1) КОРИСТЕЊЕ ИЛИ НЕМОЖНОСТ
              ЗА КОРИСТЕЊЕ НА СОФТВЕРОТ, (2) НЕМОЖНОСТА ДА СЕ КОРИСТИ СОФТВЕРОТ
              ЗА ДА СЕ ПРИСТАПИ ДО СОДРЖИНА ИЛИ ПОДАТОК; (3) ТРОШОЦИТЕ ЗА
              СНАБДУВАЊЕ НА ЗАМЕНСКИ ДОБРА ИЛИ УСЛУГИ; (4) НЕОВЛАСТЕН ПРИСТАП ДО
              ИЛИ ПРОМЕНА НА ВАШИОТ ПРЕНОС ИЛИ ПОДАТОЦИ; ИЛИ(5) БИЛО КАКВИ
              РАБОТИ ПОВРЗАНИ СО СОФТВЕРОТ.
              <br />
              Ограничувањата на правните обврски не се применуваат при намерна
              или општа занемареност од страна на Продавачот.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Доверливи Информации
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Странките засегнати се согласуваат да не споделуваат доверливи
              информации.
              <br />
              Доверливи информации се сите информации, поврзани со
              организацијата, трговски активности, финансиски и сметководствени
              информации, клиенти, доставувачи, опрема, вработени, попис на
              стока, изворен код, алгоритми, инфраструктура, технички
              спецификации, архитектура, бази на податоци, вештина, модели на
              јавни услуги, права на полуптоводна топографија и права на дизајн,
              цртежи, дијаграми и други информации, кое се однесува на правата
              на Индивидуална Сопственост на двете странки, исклучувајќи ги
              информациите кои странката јасно ја дефинирала како недоверлива,
              општо познати факти, или информации, кои се споделени јавно од
              трета странка (на пример преку реклама, изјави во медиуми,
              печатени или веб објави итн).
              <br />
              Двете странки се согласуваат да не споделуваат доверливи
              информации на трети странки во било која форма, како и да се
              осигураме дека нивните подружнички, вработени, подпотписници и
              соработници се обврзани на истите обврски во период од три години
              по прекинување.
              <br />
              Во случај на прекршување на обврските за доверливост, странката
              која ги прекршила должи на другата компензација за сите загуби кои
              ги претрпела.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Лични Податоци</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Со користење на Опремата, Софтвер и Услуги, Клиентот се согласува
              да неговите лични податоци се обработени и се чуваат од страна на
              Продавачот во согласност со Политиката за Приватност, која е
              објавена на www.agrio.mk. Продавачот го задржува правото да ја
              измени Политиката за Приватност, по информирање на Клиентот 30
              дена претходно по пат на објавување на www.agrio.com. Во случај на
              преработување на лични податоци со намена за изведување на
              одредена задача, Продавачот ќе ги обработи податоците само со
              јасна согласност од Клиентот и само онолку колку е потребно за да
              се исполнат обврските кон Продавачот под овие Услови и Политика за
              Приватност.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Услови и Прекин
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    А. освен јасно наведени во овие Услови, договорот меѓу двете
                    странки ке биде прекинат во случај на:
                    <br />
                    Истекување на претплатата или изведување Услуги, кои се
                    овозможени на Клиентот од страна на Продавачот според овие
                    Услови;
                    <br />
                    Заедничка согласност на двете странки за прекин;
                    <br />
                    Прекин на бизнис активностите на Клиентот или Продавачот;
                    <br />
                    Други случаи, кои се одредени со применето право.
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Б.во случај да било која странка ги прекрши Условите или
                    било кој заеднички договор помеѓу странките:
                    <br />
                    Другата странка е ослободена од нејзините обврски за
                    периодот од прекршувањето од првата странка. Во тој случај
                    исполнителната странка не должи никаква компензација на
                    прекршителната странка;
                    <br />
                    Исполнителната странка може да ја извести прекршителната
                    странка на пишано за да го поправи престапот во рок од 15
                    дена. Доколку прекршителната странка не го поправи престапот
                    во тој даден рок, исполнителната странка може да го прекине,
                    без дополнително известување, постоечките Услови и
                    соодветно, изведбата на обврските под тие Услови.
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    В. во случај на ран прекин, што не е вина на Продавачот,
                    Продавачот не му должи рефундациаја за предпплатената
                    претплата.
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Г. во случај на прекин, ставките кои се однесуваат
                    интелектуална сопственост и доверливите информации ќе
                    останат.
                  </div>
                </div>
              </div>
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Виша Сила</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Ниедна странка нема да одговара од причина во одложување на
              извршувањето и за неуспешно извршување на било која од нивните
              обврски ако такво одложување или неуспех се должи на природни
              катастрофи, штрајкови, војни, запаливи направи, попреченост од
              јавни и воени власти, усогласување со владини закони, права и
              регулативи, одложувања во транспорт или достава, неможноста да се
              обезбедат потребните владини приоритети како материјали, или било
              која грешка што не е во негова контрола или без негова вина или
              запоставување.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Решавање на Спорови и Применето Право
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Овие Услови ќе бидат раководени и применети според законите на
              Република Северна Македонија.
              <br />
              Сите расправи, кои потекнуваат или се поврзани со овие Услови, ќе
              бидат решени по пат на преговори помеѓу двете странки. Во случај
              да не може да се постигне договор, расправата ќе биде однесена на
              соодветниот суд во Македонија.
              <br />
              Освен ако не е задолжително со закон, ниту една странка, ниту
              нејзините подружнички, вработени, кооперанти и соработници нема да
              го сподели постоењето, содржина или исходот на било кое
              разрешување на расправата помеѓу двете странки, без пишана
              согласност од другата странка.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Целосна Согласност и Обврски
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Овие Услови ги содржат сите сфаќања и изјави помеѓу странките кои
              се однесуваат на работи од овде, и да ги наследат било кои
              договори кои се претходно направени меѓу нив па понатаму. Правата
              и обврските под ови Услови не можат да бидат доделени пред
              претходна пишана согласност од другата странка, која нема да биде
              задржана неразумо.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Амандмани</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Во случај на амандмани на овие Услови Продавачот ќе го извести
              Клиентот. Освен ако Клиентот јасно не се спротивстави на
              амандманите, променетите Услови ќе станат обврзувачки за Клиентот,
              како што е објавено на www.agrio.mk. Периодот во кој Клиентот ќе
              може да поднесе приговор ќе биде еден месец по известувањето од
              страна на Продавачот. Доколку Клиентот го испрати неговото
              несогласување за амандманите по писмен пат на info@agrio.mk, овие
              Услови ќе бидат прекинати. Прекинот не влијае на правата на
              Клиентот, кој веќе има платено некоја Опрема, Софтвер или Услуга.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Отстапки</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Во случај на било која клаузула во овие Услови се докаже дека е
              невалидна, незаконска и непредвидена, веродостојноста, законитоста
              и извршнителноста на другите клаузули нема во ниеден случај да
              биде погодена или нарушена со тоа. Странките ќе ги заменат
              неточните, нелегалните или неспроведени клаузули со валидни,
              легални и извршни, кои како што дозволува применетото право, има
              идентично или слично значење во бизнисот и правото.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Наслови на параграфите
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Насловите на параграфите се само за практичност и не може да
              влијаат на било кој начин јазикот на клаузулите на кои се
              однесуваат.
            </p>
          </div>

          <div className="mb-8">
            <p className="fw-semibold fs-6 text-gray-800">
              Последно Обновено: 29 јануари 2023
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsMk;
