import i18next from "i18next";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { sensorsNames } from "../config/filter";
import { StationContext } from "../context/station.context";

const Activity = () => {
  const { notifications, fetchNotifications } = useContext(StationContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!notifications) fetchNotifications();
  }, [notifications]);
  return (
    <div className="card card-flush h-lg-100">
      <div className="card-header pt-7">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-800">
            {t("lastNotifications")}
          </span>
          <span className="text-gray-400 mt-1 fw-semibold fs-6">
            {t("total") +
              " " +
              notifications?.length +
              " " +
              t("notifications")}
          </span>
        </h3>
      </div>
      <div className="card-body">
        <div className="m-0">
          {notifications &&
            notifications.length > 0 &&
            notifications.slice(0, 6).map((notification) => {
              return (
                <div className="timeline" key={notification._id}>
                  <div className="timeline-item align-items-center mb-7">
                    <div className="timeline-line w-20px mt-6 mb-n12"></div>
                    <div
                      className="timeline-icon"
                      style={{ marginLeft: "1px" }}
                    >
                      <span className="svg-icon svg-icon-2 svg-icon-primary">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <a
                      href={`/station/${notification.data[0].station._id}`}
                      className="timeline-content m-0"
                    >
                      <span className="fs-6 text-gray-700 fw-bold d-block">
                        {t("station")}{" "}
                        {i18next.language === "mk"
                          ? notification.data[0].station.cyrilicName
                          : notification.data[0].station.name.replace(
                              "_",
                              " "
                            )}{" "}
                        {t("criticalPoint")} "
                        {t(notification.data[0].alarm.filter.replace("1", ""))}"{" "}
                        {notification.data[0].alarm.comparing} {t("from")}{" "}
                        {notification.data[0].alarm.value}{" "}
                        {sensorsNames[notification.data[0].alarm.filter].symbol}
                      </span>
                      <span className="fs-7 fw-semibold text-gray-400">
                        {moment(notification.created)
                          .locale(i18next.language)
                          .format("Do MMMM YYYY, HH:mm")}
                      </span>
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Activity;
