import { useForm } from "react-hook-form";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth.context";
import logo from "../assets/images/logo.svg";
import bg from "../assets/images/bg.jpeg";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { register, handleSubmit } = useForm();
  const { authLogin } = useContext(AuthContext);
  const { t } = useTranslation();

  const handleLogin = (data) => {
    authLogin(data);
  };

  return (
    <div
      className="auth"
      style={{
        backgroundColor: "#ddd",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "100%",
        backgroundPosition: "center center",
      }}
    >
      <div className="card rounded-3 w-md-550px">
        <div className="card-body p-10 p-lg-20">
          <form
            className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            onSubmit={handleSubmit(handleLogin)}
          >
            <div className="text-center mb-5">
              <img height={100} src={logo} alt="Logo" />
            </div>
            <div className="text-center mb-11">
              <h1 className="text-dark fw-bolder mb-3">{t("login")}</h1>
            </div>

            <div className="fv-row mb-8 fv-plugins-icon-container">
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder={t("emailAddress")}
                {...register("email")}
              />
            </div>

            <div className="fv-row mb-3 fv-plugins-icon-container">
              <input
                type="password"
                className="form-control"
                id="userpassword"
                placeholder={t("password")}
                {...register("password")}
              />
            </div>

            <div className="d-grid mb-10">
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-primary"
              >
                <span className="indicator-label">{t("continue")}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
