import axios from "axios";
import { useState, createContext } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const AlarmContext = createContext({
  alarms: false,
  setAlarms: () => null,
  events: false,
  setEvents: () => null,
  deleteAlarm: () => null,
});

export const AlarmProvider = ({ children }) => {
  const [alarms, setAlarms] = useState(null);
  const [events, setEvents] = useState(null);
  const token = useSelector((state) => state.user.token);

  /*------------------------------------------------------------------------------------
	// Fetch all user alarms
  ------------------------------------------------------------------------------------*/
  const fetchUserAlarms = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}alarms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAlarms(res.data.alarms);
      })
      .catch((err) => {});
  };

  /*------------------------------------------------------------------------------------
	// Create new alarm
  ------------------------------------------------------------------------------------*/
  const createNewAlarm = (data) => {
    // return false;
    axios
      .post(`${process.env.REACT_APP_API_URL}alarms/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // Refetch alarms
        fetchUserAlarms();
        toast.success("Алармот е успешно додаден");
      })
      .catch((err) => {});
  };

  /*------------------------------------------------------------------------------------
	// Delete alarm
  ------------------------------------------------------------------------------------*/
  const deleteAlarm = (alarmID) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}alarms/${alarmID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // Refetch alarms
        fetchUserAlarms();
        toast.success("Алармот е успешно избришан");
      })
      .catch((err) => {});
  };

  /*------------------------------------------------------------------------------------
	// Fetch all events
  ------------------------------------------------------------------------------------*/
  const fetchEvents = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}events`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEvents(res.data.events);
      })
      .catch((err) => {});
  };

  const value = {
    alarms,
    setAlarms,
    events,
    setEvents,
    fetchUserAlarms,
    createNewAlarm,
    fetchEvents,
    deleteAlarm,
  };
  return (
    <AlarmContext.Provider value={value}>{children}</AlarmContext.Provider>
  );
};
