import React from "react";
import "../webview.style.css";

const AboutSq = () => {
  return (
    <div className="flex-lg-row-auto">
      <div className="card ">
        <div className="card-body">
          <div className="mb-7">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              "AGRIO" - një zgjidhje inovative softuerike për fermerët
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              AGRIO" është një model softuerësh dhe aplikacioni celular për
              parashikimin e sëmundjeve që përfaqëson një përmirësim të një
              stacioni agrometeorologjik dhe aplikimin e sensorëve. Ky softuer
              synon të lehtësojë plotësisht punën e tyre, duke rritur
              rendimentet dhe duke ulur njëkohësisht kostot e prodhimit.
            </p>

            <p className="fw-semibold fs-6 text-gray-600">
              Përdorimi i sensorëve dhe stacioneve meteorologjike, të
              përmirësuara me "AGRIO" si një mjet praktik dixhital, do t'u
              mundësojë fermerëve individualë dhe prodhuesve më të mëdhenj të
              produkteve bujqësore, njohuri dhe monitorim të parametrave të
              rëndësishëm për funksionimin, dhe në këtë mënyrë do t'i ndihmojë
              ata në marrjen e vendimeve të sakta. përcaktimi i periudhës së
              mbjelljes, masat parandaluese për mbrojtjen nga sëmundjet dhe
              dëmtuesit, ekzekutimi i saktë i masave agroteknike etj. Të dhënat
              maten edhe për vendndodhjen ku është vendosur stacioni dhe i cili
              kalibrohet sipas të dhënave të matura nga vetë stacioni.
              Parashikimi i stacionit përfshin:
            </p>

            <div className="my-2">
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Temperatura e ajrit
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Lagështia relative e ajrit
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Sasia e reshjeve
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Presioni atmosferik
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Shpejtësia dhe drejtimi i erës
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">Vranësira</div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Mjegull (dukshmëri)
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Pika e vesës
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Kushtet e stuhisë
                </div>
              </div>

              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Pamja e akullit
                </div>
              </div>
            </div>
          </div>

          <p className="fw-semibold fs-6 text-gray-600">
            E gjithë kjo kontribuon në kursimin e ripromaterialeve, kohës dhe
            energjisë, si dhe uljen e ndjeshme të kostove dhe uljen e ndotjes së
            mjedisit. Këto të dhëna janë të rëndësishme për çdo kulturë
            bujqësore dhe periudhën e saj bimore. Kjo do të thotë që të gjithë
            fermerët do të dinë saktësisht se sa ujë kanë humbur në tokë dhe
            kështu do të dinë saktësisht se kur dhe sa duhet të ujiten. Kjo
            kursen konsumin e pakontrolluar të ujit, por kursen edhe larjen e
            lëndëve ushqyese në tokë.
          </p>
          <p className="fw-semibold fs-6 text-gray-600">
            “Agroptima” është kompani maqedonase e cila me mbështetjen e Fondit
            për Inovacion dhe Zhvillim Teknologjik krijoi platformën digjitale
            “AGRIO”.
          </p>

          <p className="fw-semibold fs-6 text-gray-600">
            Si kompani, "Agroptima" është furnizues i plehrave të lëngëta të
            cilësisë së lartë me origjinë nga BE dhe është i lidhur ngushtë me
            prodhimin bujqësor në Maqedoni, Bullgari, Serbi dhe Shqipëri.
            Kompania punon në drejtim të lehtësimit të punës së fermerëve,
            rritjes së rendimenteve duke ulur njëkohësisht kostot e prodhimit,
            duke mundësuar zgjidhje për dixhitalizimin e bujqësisë.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSq;
