import React from "react";
import { useParams } from "react-router-dom";
import AboutEn from "./about/en";
import AboutMk from "./about/mk";
import "./webview.style.css";

const WebviewAbout = () => {
  const { lang = "en" } = useParams();
  switch (lang) {
    case "mk":
      return <AboutMk />;
    default:
      return <AboutEn />;
  }
};

export default WebviewAbout;
