import React from "react";
import "../webview.style.css";

const FitrMk = () => {
  return (
    <div className="flex-lg-row-auto">
      <div className="card ">
        <div className="card-body">
          <div className="mb-7">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Mission and goals
            </h2>

            <p className="fw-bold text-gray-800">
              The Fund for Innovation and Technological Development is a leading
              government institution for supporting startups and innovative
              companies in the Republic of North Macedonia.
            </p>
            <p className="fw-semibold fs-6 text-gray-600">
              Currently, the Fund, through its financial instruments,
              co-finances 686 projects with a joint investment of 86 million
              euros. As much as 51% of the FITR portfolio, ie 326 supported
              companies are startup companies, mostly founded by young people.
              With almost 1.5 million euros, the Fund also supports the
              development of three accelerators in the country: X Factor, Seavus
              Accelerator and Business-Technology Accelerator UKIM.
            </p>

            <p className="fw-bold text-gray-800">
              The main priorities of the Fund are improved access to financial
              support for innovation and technological development and promotion
              and encouragement of innovation activity in North Macedonia.
            </p>

            <div className="my-10">
              <p className="fw-bold text-gray-800">Our activities include:</p>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Co-financing of startups, micro, small and medium enterprises,
                  registered in the Republic of North Macedonia.
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  National Startup Council whose goal is the transformation of
                  the Macedonian startup ecosystem
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Corporate Innovation Program to encourage collaboration
                  between the private sector and startups
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Partnership with Amazon Web Services through which the Fund
                  provides loans for the use of this global platform in the
                  amount of 10,000, 25,000 and 100,000 US dollars
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Separate portfolio for investments in digitalization of
                  agriculture and public institutions
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Development of a National Strategy for Artificial Intelligence
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Mentoring support for potential and current users
                </div>
              </div>
            </div>
          </div>

          <p className="fw-bold text-gray-800">
            The fund also supports talented elementary, high school and college
            students.
          </p>
          <p className="fw-semibold fs-6 text-gray-600">
            As part of the four Challenges for Young Researchers, 109 school
            projects were awarded to over 728 students and mentors from across
            the country. For the first time, we implemented a Challenge for the
            establishment of production laboratories “Young people create”.
            Through the support of Junior Achievement Macedonia and the
            Entrepreneurship Education Program in all high schools across the
            country, 728 students and 100 teachers. Under the UPSHIFT program,
            the Fund awarded 32 most successful ideas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FitrMk;
