import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TermsAndConditionsEn from "./terms/en";
import TermsAndConditionsMk from "./terms/mk";
import "./webview.style.css";

const WebviewTermsConditions = () => {
  const { lang = "en" } = useParams();
  switch (lang) {
    case "mk":
      return <TermsAndConditionsMk />;
    default:
      return <TermsAndConditionsEn />;
  }
};

export default WebviewTermsConditions;
