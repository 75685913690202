import React from "react";
import "../webview.style.css";

const AboutSr = () => {
  return (
    <div className="flex-lg-row-auto">
      <div className="card ">
        <div className="card-body">
          <div className="mb-7">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              "АГРИО" - иновативно софтверско решење за пољопривреднике
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              АГРИО" је софтверски модел за прогнозу болести и мобилна
              апликација која представља надоградњу агрометеоролошке станице и
              примену сензора. Овај софтвер има за циљ да у потпуности олакша
              њихов рад, повећањем приноса и истовремено смањењем трошкова
              производње.
            </p>

            <p className="fw-semibold fs-6 text-gray-600">
              Коришћење сензора и метеоролошких станица, надограђених „АГРИО“
              као практичним дигиталним алатом, омогућиће индивидуалним
              пољопривредницима и већим произвођачима пољопривредних производа,
              увид и праћење важних параметара за рад, а самим тим ће им помоћи
              у доношењу исправних одлука. одређивање рока садње, превентивне
              мере заштите од болести и штеточина, прецизно извођење
              агротехничких мера и др. Мере се и подаци за локацију на којој се
              налази станица и која се калибрише према измереним подацима са
              саме станице. Прогноза станице укључује:
            </p>

            <div className="my-2">
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Температура ваздуха
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Релативна влажност ваздуха
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Количина падавина
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Атмосферски притисак
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Брзина и правац ветра
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">Облачност</div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Магла (видљивост)
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">Тачка росе</div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Олујни услови
                </div>
              </div>

              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Појава леда
                </div>
              </div>
            </div>
          </div>

          <p className="fw-semibold fs-6 text-gray-600">
            Све ово доприноси уштеди репроматеријала, времена и енергије, као и
            значајном смањењу трошкова и смањењу загађења животне средине. Ови
            подаци су важни за сваку пољопривредну културу и њен вегетациони
            период. То значи да ће сви фармери тачно знати колико су воде
            изгубили у земљишту и самим тим тачно знати када и колико да
            наводњавају. Тиме се штеди неконтролисана потрошња воде, али се
            штеди и на испирању хранљивих материја у тлу.
          </p>
          <p className="fw-semibold fs-6 text-gray-600">
            "Агроптима" је македонска компанија која је уз подршку Фонда за
            иновације и технолошки развој креирала дигиталну платформу "АГРИО".
          </p>

          <p className="fw-semibold fs-6 text-gray-600">
            Као компанија, „Агроптима” је добављач висококвалитетних течних
            ђубрива пореклом из ЕУ и уско је повезана са пољопривредном
            производњом у Македонији, Бугарској, Србији и Албанији. Компанија
            ради у правцу олакшавања рада пољопривредника, повећања приноса уз
            истовремено смањење трошкова производње, омогућавање решења за
            дигитализацију пољопривреде.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSr;
