import React from "react";
import { ReactComponent as Compass } from "../../assets/images/compass.svg";
import { ReactComponent as Arrow } from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";

const WindCard = ({ windSpeed, windDirection }) => {
  const { t } = useTranslation();
  return (
    <div className="card card-flush h-100">
      <div className="card-header pt-7">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-800">{t("wind")}</span>
          <span className="text-gray-400 mt-1 fw-semibold fs-6">
            {t("windMeasure")}
          </span>
        </h3>
      </div>
      <div className="card-body px-0 pt-3 pb-5 w-100 wind-widget">
        <Compass width={250} height={250} />
        <Arrow
          width={180}
          height={180}
          style={{
            transform: `rotate(${windDirection - 330}deg)`,
          }}
          className="arrow"
        />
        <div className="wind-value">
          <span>{windSpeed}</span>
          <span style={{ fontSize: 12, color: "#b5b5c3" }}>km/h</span>
        </div>
      </div>
    </div>
  );
};

export default WindCard;
