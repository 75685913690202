// import { ACTION_TYPES } from './user.types';

export const setCurrentUser = (user) => {
	return { type: 'SET_CURRENT_USER', payload: user };
}

export const removeCurrentUser = () => {
	return { type: 'REMOVE_CURRENT_USER', payload: '' };
}

export const checkCurrentUser = (user) => {
	return { type: 'CHECK_CURRENT_USER', payload: user };
}