import { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ListAlarms from "../components/alarms/list-alarms.component";
import NewAlarm from "../components/alarms/new-alarm.component";
import Page from "../components/page.component";
import { AlarmContext } from "../context/alarm.context";

const Alarms = ({ station }) => {
  const { alarms, fetchUserAlarms } = useContext(AlarmContext);
  const [stationAlarms, setStationAlarms] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    //Get all alarms
    fetchUserAlarms();
  }, []);

  useEffect(() => {
    if (!station) setStationAlarms(alarms);
    else {
      let _alarms = [];
      alarms &&
        alarms.map((alarm) => {
          if (alarm.station._id === station) _alarms.push(alarm);
        });

      setStationAlarms(_alarms);
    }
  }, [alarms]);

  return (
    <Page title="Аларми" className={`${station ? "" : "app-container"}`}>
      {!station ? (
        <div className="row">
          <div className="col-4 mb-5">
            <h1>{t("alarms")}</h1>
            <ul className="breadcrumb breadcrumb-dot fw-semibold text-gray-600 fs-7 my-1">
              <li className="breadcrumb-item text-gray-600 pe-0">
                <Link to="/" className="text-gray-600 text-hover-primary">
                  {t("homepage")}
                </Link>
              </li>
              <li className="breadcrumb-item text-gray-500">{t("alarms")}</li>
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row align-items-start">
        <div className="col-12 col-sm-6 col-lg-6">
          <ListAlarms alarms={stationAlarms} station={station} />
        </div>
        <div className="col-12 col-sm-6 col-lg-6">
          <NewAlarm station={station} />
        </div>
      </div>
    </Page>
  );
};

export default Alarms;
