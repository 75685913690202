import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import wind from "../../../assets/images/icons/wind.png";
import raining from "../../../assets/images/icons/raining.png";
import humidity from "../../../assets/images/icons/humidity.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const WeatherTab = ({ station }) => {
  const [city, setCity] = useState(null);
  const [forecast, setForecast] = useState(null);
  const [icons, setIcons] = useState([]);
  const [activeDay, setActiveDay] = useState("day_0");
  const { t } = useTranslation();

  useEffect(() => {
    const getForecast = () => {
      axios
        .get(
          `https://api.weather4all.com.mk/07a792c523bc5e1295f473eb75d7b745/${station.forecastCity}`
        )
        .then(({ data }) => {
          setCity(
            i18next.language === "mk" || i18next.language === "sr"
              ? data.request.name
              : data.request.latin_name
          );
          setForecast(data.data.weather);
        });
    };

    const getIcons = () => {
      axios
        .get(
          "https://api.weather4all.com.mk/07a792c523bc5e1295f473eb75d7b745/weatherstate"
        )
        .then((icons) => {
          setIcons(icons.data);
        });
    };

    if (station.cyrilicName) {
      getForecast();
      getIcons();
    }
  }, [station]);

  return (
    <>
      <div className="text-center mt-5">
        <div className="text-gray-400 fw-semibold fs-5">
          {t("forecastNextWeek")}
        </div>
        <h1 className="fs-2hx fw-bold ">{city}</h1>
      </div>
      <div className="card h-xl-100">
        <div className="card-header position-relative py-0 border-bottom-2 justify-content-around">
          <ul
            className="nav nav-stretch nav-pills nav-pills-custom d-flex mt-3"
            role="tablist"
          >
            {forecast &&
              forecast.map((day, key) => {
                return (
                  <li
                    className="nav-item p-0 ms-0 me-8"
                    key={key}
                    onClick={() => setActiveDay("day_" + key)}
                  >
                    <div
                      className={`nav-link btn btn-color-muted px-0 ${
                        activeDay === "day_" + key ? "active" : ""
                      }`}
                    >
                      <span className="nav-text fw-semibold fs-4 mb-3 text-capitalize">
                        {key === 0
                          ? t("today")
                          : moment(day.date)
                              .locale(i18next.language)
                              .format("dddd")}
                      </span>

                      <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="card-body">
          <div className="tab-content mb-2">
            {forecast &&
              forecast.map((day, key) => {
                return (
                  <div
                    className={`tab-pane fade ${
                      activeDay === "day_" + key ? "active show" : ""
                    }`}
                    key={key}
                  >
                    <div className="d-flex flex-wrap align-items-stretch">
                      <div className="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center me-5">
                          <div className="symbol symbol-60px me-3">
                            <img
                              src={`https://weather4all.com.mk${
                                icons[day.weatherCode].day_icon
                              }`}
                              width={50}
                              className={`me-1`}
                              alt={day.weatherDesc}
                            />
                          </div>
                          <div className="me-5">
                            <span className="text-gray-400 fw-bold fs-6 me-2">
                              {day.mintempC.replace("+", "")}
                              <span className="fw-semibold fs-6">°C</span>
                            </span>
                            <span className="text-gray-800 fw-bold fs-3">
                              {day.maxtempC.replace("+", "")}
                              <span className="fw-semibold fs-6">°C</span>
                            </span>
                            <span className="fw-semibold fs-7 d-block text-start text-primary ps-0">
                              {day.weatherDesc}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center me-10">
                          <div className="symbol symbol-40px me-6 mt-3">
                            <img src={wind} alt="{wind}" className={`me-2`} />
                          </div>
                          <div className="me-5">
                            <span className="text-gray-800 fw-bold fs-6">
                              {day.windspeedKmph + " "}
                              <span className="fw-semibold fs-6 text-gray-400">
                                km/h
                              </span>
                            </span>
                            <span className="fw-semibold fs-7 d-block text-start text-primary ps-0">
                              {t("wind")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center me-5">
                          <div className="symbol symbol-40px me-6 mt-3">
                            <img
                              src={raining}
                              alt="{raining}"
                              className={`me-2`}
                            />
                          </div>
                          <div className="me-5">
                            <span className="text-gray-800 fw-bold fs-6">
                              {day.precipMM + " "}
                              <span className="fw-semibold fs-6 text-gray-400">
                                l/m2
                              </span>
                            </span>
                            <span className="fw-semibold fs-7 d-block text-start text-primary ps-0">
                              {t("rain")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center me-5">
                          <div className="symbol symbol-40px me-6 mt-3">
                            <img
                              src={humidity}
                              alt="{humidity}"
                              className={`me-2`}
                            />
                          </div>
                          <div className="me-5">
                            <span className="text-gray-800 fw-bold fs-6">
                              {day.humidity + " "}
                              <span className="fw-semibold fs-6 text-gray-400">
                                %
                              </span>
                            </span>
                            <span className="fw-semibold fs-7 d-block text-start text-primary ps-0">
                              {t("humidity")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table align-middle mb-10">
                        <tbody>
                          <tr>
                            <td
                              className="bg-light rounded text-gray-600 fs-12 fw-bold px-10 py-5"
                              colSpan="4"
                            >
                              {day.text_forecast}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="table align-middle">
                        <thead>
                          <tr>
                            <th className="min-w-100px text-bold">
                              {t("time")}
                            </th>
                            <th className="min-w-100px text-bold">
                              {t("temperature")}
                            </th>
                            <th className="min-w-100px text-bold text-center">
                              {" "}
                              <i className="fas fs-3 fa-tint me-2 text-primary"></i>{" "}
                              {t("humidity")}
                            </th>
                            <th className="min-w-100px text-bold text-center">
                              {" "}
                              <i className="fas fs-3 fa-wind me-2 text-primary"></i>{" "}
                              {t("wind")}
                            </th>
                            <th className="min-w-100px text-bold text-end">
                              <i className="fas fs-3 fa-cloud-showers-heavy me-2 text-primary"></i>{" "}
                              {t("rain")}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {day.hourly.map((hour, k) => {
                            return (
                              <tr key={k}>
                                <td className="fs-6 fw-bold text-gray-800">
                                  {moment(
                                    day.date +
                                      " " +
                                      hour.local_time.slice(0, 2) +
                                      ":" +
                                      hour.local_time.slice(2)
                                  )
                                    .locale(i18next.language)
                                    .format("HH:mm")}{" "}
                                  {t("hour")}
                                </td>
                                <td className="fs-6 fw-bold text-gray-400">
                                  <span className="text-gray-800">
                                    <img
                                      src={`https://weather4all.com.mk${
                                        icons[hour.weatherCode].day_icon
                                      }`}
                                      width={30}
                                      className={`me-1`}
                                      alt={hour.weatherDesc}
                                    />
                                    {hour.tempC.replace("+", "") + " °C"}
                                    <span className="text-gray-400 ms-5">
                                      {hour.weatherDesc}
                                    </span>
                                  </span>
                                </td>
                                <td className="fs-6 text-gray-800 text-center">
                                  <span className="text-gray-400">
                                    {hour.humidity} %
                                  </span>
                                </td>
                                <td className="fs-6 text-gray-800 text-center">
                                  <span className="text-gray-400">
                                    {hour.windspeedKmph} km/h
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-400">
                                    {hour.precipMM} l/m2
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default WeatherTab;
