import Page from "../components/page.component";
import { Fragment, useContext, useEffect, useState } from "react";
import { LoaderContext } from "../context/loader.context";
// import "moment/locale/mk";
// import moment from "moment";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Link, useParams } from "react-router-dom";
import { StationContext } from "../context/station.context";
import { useSelector } from "react-redux";
import axios from "axios";
import HistoricalParams from "../components/historical/parametars.component";
import { groupingUnits } from "../config/highcharts.config";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/windbarb")(Highcharts);
// require("highcharts/modules/lollipop")(Highcharts);
highchartsMore(Highcharts);

const HistoricalStation = () => {
  const { loader } = useContext(LoaderContext);
  const { fetchSingleStation } = useContext(StationContext);
  const { stationSlug } = useParams();
  const station = useSelector((state) => state.stations.activeStation);
  const [chart, setChart] = useState();
  const [chartData, setChartData] = useState(null);
  const [series, setSeries] = useState([]);
  const token = useSelector((state) => state.user.token);
  const [activeType, setActiveType] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    !station && fetchSingleStation(stationSlug);
  }, []);

  function sortFunction(a, b) {
    if (a[0] === b[0]) {
      return 0;
    } else {
      return a[0] < b[0] ? -1 : 1;
    }
  }

  useEffect(() => {
    if (stationSlug) {
      let temperature = [];
      let precipitation = [];
      let dewPoint = [];
      let airHumidity = [];
      let solarRadiation = [];
      let evapotranspiration = [];
      let windSpeed = [];
      let leafWetness = [];
      let earthTemperature = [];
      let earthHumidity = [];
      let airPressure = [];
      let chartData = [];

      axios
        .get(`${process.env.REACT_APP_API_URL}station/history/${stationSlug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          Object.keys(data).forEach(function (key, index) {
            let item = data[key];

            let temp = Math.round(item.airTemperature * 10) / 10;

            temperature.push([item.timestamp, temp]);
            precipitation.push([item.timestamp, item.precipitation]);
            dewPoint.push([item.timestamp, item.dewPoint]);
            airHumidity.push([item.timestamp, item.airHumidity]);
            solarRadiation.push([item.timestamp, item.solarRadiation]);
            evapotranspiration.push([item.timestamp, item.evapotranspiration]);
            windSpeed.push([item.timestamp, item.windSpeed]);
            leafWetness.push([item.timestamp, item.leafWetness1]);
            earthTemperature.push([item.timestamp, item.earthTemperature1]);
            earthHumidity.push([item.timestamp, item.earthHumidity1]);
            airPressure.push([item.timestamp, item.airPressure]);
          });

          temperature.sort(sortFunction);
          precipitation.sort(sortFunction);
          dewPoint.sort(sortFunction);
          airHumidity.sort(sortFunction);
          solarRadiation.sort(sortFunction);
          evapotranspiration.sort(sortFunction);
          windSpeed.sort(sortFunction);
          leafWetness.sort(sortFunction);
          earthTemperature.sort(sortFunction);
          earthHumidity.sort(sortFunction);
          airPressure.sort(sortFunction);

          const _series = {
            airTemperature: {
              id: "airTemperature",
              name: t("temperature"),
              type: "spline",
              lineWidth: 1,
              data: temperature,
              enabled: false,
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}°C</b><br> ',
              },
            },
            airHumidity: {
              id: "airHumidity",
              name: t("airHumidity"),
              type: "spline",
              data: airHumidity,
              height: "33.333%",
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}%</b><br> ',
              },
            },
            solarRadiation: {
              id: "solarRadiation",
              name: t("solarRadiation"),
              type: "areaspline",
              fillOpacity: 0.5,
              color: "#D5E11E",
              data: solarRadiation,
              lineWidth: 0,
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}l/m2</b><br> ',
              },
            },
            precipation: {
              id: "precipation",
              name: t("rains"),
              type: "column",
              pointWidth: 5,
              color: "#13b4ea",
              data: precipitation,
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}l/m2</b><br> ',
              },
            },
            airPressure: {
              id: "airPressure",
              name: t("airPressure"),
              data: airPressure,
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f} hPa</b><br> ',
              },
              color: "#fd7e14",
              dashStyle: "shortdot",
              shadow: false,
            },
            leafWetness1: {
              id: "leafWetness1",
              name: t("leafWetness"),
              type: "spline",
              color: "#075700",
              data: leafWetness,
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}°C</b><br> ',
              },
              dashStyle: "ShortDashDotDot",
              marker: {
                enabled: false,
              },
            },
            evapotranspiration: {
              id: "evapotranspiration",
              name: t("evapotranspiration"),
              type: "spline",
              color: "#035700",
              data: evapotranspiration,
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}°C</b><br> ',
              },
              marker: {
                enabled: true,
              },
            },
            windSpeed: {
              id: "windSpeed",
              type: "area",
              data: windSpeed,
              color: Highcharts.getOptions().colors[0],
              fillColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, Highcharts.getOptions().colors[0]],
                  [
                    1,
                    Highcharts.color(Highcharts.getOptions().colors[0])
                      .setOpacity(0.25)
                      .get(),
                  ],
                ],
              },
              name: t("windSpeed"),
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f} m/s</b><br> ',
              },
              states: {
                inactive: {
                  opacity: 0.5,
                },
              },
            },
            dewPoint: {
              id: "dewPoint",
              name: t("dewPoint"),
              type: "spline",
              color: "#4E9097",
              data: dewPoint,
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}°C</b><br> ',
              },
            },
            earthTemperature1: {
              id: "earthTemperature1",
              name: t("earthTemperature"),
              color: "#A52A2A",
              pointWidth: 3,
              data: earthTemperature,
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}°C</b><br> ',
              },
            },
            earthHumidity1: {
              id: "earthHumidity1",
              name: t("earthHumidity"),
              type: "spline",
              data: earthHumidity,
              tooltip: {
                pointFormat:
                  '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}%</b><br> ',
              },
              color: "#28D7E8",
              dashStyle: "LongDash",
              shadow: false,
            },
            // {
            //   type: "windbarb",
            //   data: wind,
            //   name: "Wind",
            //   color: Highcharts.getOptions().colors[1],
            //   showInLegend: false,
            //   tooltip: {
            //     valueSuffix: " m/s",
            //   },
            // },
          };

          setChartData(_series);
        });
    }
  }, []);

  useEffect(() => {
    if (series.length > 0) {
      let config = {
        rangeSelector: {
          allButtonsEnabled: true,
          buttonSpacing: 10,
          buttons: [
            {
              type: "day",
              count: 7,
              text: t("7days"),
            },
            {
              type: "day",
              count: 30,
              text: t("30days"),
            },
            {
              type: "month",
              count: 3,
              text: t("3months"),
            },
            {
              type: "month",
              count: 6,
              text: t("6months"),
            },
            {
              type: "year",
              count: 1,
              text: t("1year"),
            },
            {
              type: "ytd",
              text: "YTD",
            },
          ],
          selected: 1,
          buttonTheme: {
            width: "auto",
            fill: "transparent",
            padding: 10,
            style: {
              color: "#a1a5b7",
            },
            states: {
              hover: {},
              select: {
                fill: "#f5f8fa",
                style: {
                  color: "#7e8299",
                },
              },
              disabled: {
                style: {
                  opacity: 0.8,
                },
              },
            },
          },
          inputBoxStyle: {
            fontSize: 12,
            right: "80px",
          },
        },
        chart: {
          zoomType: "x",
          prototype: {
            viewData: {},
          },
        },

        exporting: {
          showTable: false,
          tableCaption: "Data table",
          csv: {
            dateFormat: "%Y-%m-%d  %H:%M:%S",
          },
        },

        accessibility: {
          enabled: false,
        },

        credits: {
          enabled: false,
        },
        options: {
          style: {
            fontFamily: "Roboto, sans-serif",
          },
        },

        xAxis: {
          type: "datetime",
          enabled: false,
          title: {
            text: t("time"),
          },
        },

        plotOptions: {
          series: {
            showInLegend: true,
            dataGrouping: {
              units: groupingUnits,
              dateTimeLabelFormats: {
                millisecond: ["%A, %b %e, %H:%M:%S.%L"],
                second: ["%A, %b %e, %H:%M:%S"],
                minute: ["%A, %b %e, %H:%M"],
                hour: ["%A, %b %e, %H:%M"],
                day: ["%A, %b %e, %Y"],
                week: ["Неделата од %b %e, %Y"],
                month: ["%B %Y"],
                year: ["%Y"],
              },
            },
          },
        },

        series: series,
      };

      // set the yAxis options
      let yAxis = [];
      series.map((serie, key) => {
        yAxis.push({
          title: {
            text: serie.name,
          },
          height: 100 / series.length + "%",
          top: (100 / series.length) * key + "%",
          offset: 0,
        });

        series[key].yAxis = key;
      });

      // set chart height
      config.chart.height = series.length > 1 ? series.length * 250 : 400;

      // merge the options
      config = { ...config, ...{ yAxis: yAxis } };

      // init the chart
      setChart(config);
    }
  }, [series]);

  useEffect(() => {
    let _tmpData = [];
    activeType.map((data) => {
      if (chartData[data] !== undefined) _tmpData.push(chartData[data]);
    });

    setSeries(_tmpData);
  }, [activeType]);

  return !loader && chartData ? (
    <Page title={t("historicalData")} className={"app-container"}>
      <div className="row">
        <div className="col-8 mb-5">
          <h1>
            {t("historicalDataFor")}{" "}
            {i18next.language === "mk" || i18next.language === "sr"
              ? station?.cyrilicName
              : station?.name}
          </h1>
          <ul className="breadcrumb breadcrumb-dot fw-semibold text-gray-600 fs-7 my-1">
            <li className="breadcrumb-item text-gray-600 pe-0">
              <Link to="/" className="text-gray-600 text-hover-primary">
                {t("homepage")}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-600 pe-0">
              <Link
                to={`/station/${station?._id}`}
                className="text-gray-600 text-hover-primary"
              >
                {i18next.language === "mk" || i18next.language === "sr"
                  ? station?.cyrilicName
                  : station?.name}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-600 pe-0">
              {t("historicalData")}
            </li>
          </ul>
        </div>
        <div className="col-4 mb-5 text-end"></div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-xs-12 col-sm-4 col-lg-3">
          <div className="card card-flush h-lg-100">
            <div className="card-header py-7">
              <HistoricalParams
                activeType={activeType}
                setActiveType={setActiveType}
                station={station}
              />
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-xs-12 col-sm-8 col-lg-9">
          <div className="card card-flush">
            <div className="card-header pt-7">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  {t("allData")}
                </span>
                <span className="text-gray-400 mt-1 fw-semibold fs-6">
                  {t("selectPeriod")}
                </span>
              </h3>
            </div>
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                allowChartUpdate={true}
                options={chart}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  ) : (
    <div className="loader">
      <p className="text">{t("dataLoading")}</p>
    </div>
  );
};

export default HistoricalStation;
