import React from "react";
import "../webview.style.css";

const AboutEn = () => {
  return (
    <div className="flex-lg-row-auto">
      <div className="card ">
        <div className="card-body">
          <div className="mb-7">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              "AGRIO" - an innovative software solution for farmers
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              "AGRIO" is a model software for forecasting diseases and a mobile
              application that represent the upgrade of an agrometeorological
              station and the application of sensors, This software aims to
              completely facilitate their work, by increasing the yields and
              simultaneously reducing costs in production.
            </p>

            <p className="fw-semibold fs-6 text-gray-600">
              Using sensors and meteorological stations, upgraded with "AGRIO"
              as a practical digital tool, will allow individual farmers and
              larger producers of agricultural products, insight and monitoring
              of important parameters for the operation, and thus will help them
              in making correct decisions. decisions on determining the planting
              period, preventive measures for protection against diseases and
              pests, precise execution of agrotechnical measures, etc. Data is
              also measured for the location where the station is placed and
              which is calibrated according to the measured data from the
              station itself. The station's forecast includes:
            </p>

            <div className="my-2">
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Air temperature
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Relative air humidity
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Amount of precipitation,
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Air pressure
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Wind speed and direction,
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Cloud cover
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Fog (visibility)
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">Dew point</div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Chances of storms
                </div>
              </div>

              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Appearance of ice
                </div>
              </div>
            </div>
          </div>

          <p className="fw-semibold fs-6 text-gray-600">
            All this contributes to saving repromaterials, time and energy as
            well as a significant reduction in costs and reducing environmental
            pollution. These data are important for everyone agricultural crops
            and their vegetation period. This means that all farmers will know
            exactly how much water they have lost in the soil and thus know
            exactly when and how much to irrigate. This saves uncontrolled
            consumption of water, but also saves money leaching of nutrients
            into the soil.
          </p>
          <p className="fw-semibold fs-6 text-gray-600">
            "Agroptima" is a Macedonian company that with the support of the
            Innovation Fund and technological development, created the digital
            platform "AGRIO".
          </p>

          <p className="fw-semibold fs-6 text-gray-600">
            As a company, "Agroptima" is a supplier of high-quality liquid
            fertilizers with origin from the EU and is closely related to
            agricultural production in Macedonia, Bulgaria, Serbia and Albania.
            The company works in the direction of facilitating the work of
            farmers, increasing yields while simultaneously reducing costs in
            production enabling solutions for digitization of agriculture.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutEn;
