import { useContext } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import EventCard from "../components/events/event-card.component";
import Page from "../components/page.component";
import { AlarmContext } from "../context/alarm.context";

const Events = () => {
  const { fetchEvents, events } = useContext(AlarmContext);
  const { t } = useTranslation();

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <Page title="Аларми" className={"app-container"}>
      <div className="row">
        <div className="col-4 mb-5">
          <h1>{t("events")}</h1>
          <ul className="breadcrumb breadcrumb-dot fw-semibold text-gray-600 fs-7 my-1">
            <li className="breadcrumb-item text-gray-600 pe-0">
              <Link to="/" className="text-gray-600 text-hover-primary">
                {t("homepage")}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-500">{t("events")}</li>
          </ul>
        </div>
      </div>

      <div className="row align-items-start">
        <div className="col-12 col-sm-12 col-lg-12">
          <div className="row">
            {events &&
              events.map((event) => (
                <EventCard event={event} key={event._id} />
              ))}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Events;
