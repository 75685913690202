import React from "react";
import "../webview.style.css";

const TermsAndConditionsEn = () => {
  return (
    <div className="flex-lg-row-auto">
      <div className="card ">
        <div className="card-body">
          <div className="mb-7">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              TERMS & CONDITIONS <br />
              GENERAL TERMS & CONDITIONS
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              for AGRIO equipment, software and services for International
              Clients
            </p>

            <p className="fw-semibold fs-6 text-gray-600">
              The Vendor supplies Equipment, and provides Software and Services
              under the “Agrio” registered trademark to Clients under the
              conditions described in these General Terms &amp; Conditions for
              Agrio Equipment, Software and Services for international clients
              (hereinafter “Terms”). These terms apply only to business clients
              and deliveries outside the territory of North of Macedonia. In
              case that you are a consumer, please contact us at{" "}
              <a href="mailto:info@agrio.mk">info@agrio.mk</a>
              and we will provide you with our proposal for individuals. The
              Terms are a contract between the Vendor and a Client, who has
              purchased Equipment or Services directly from the Vendor, with a
              subject, as described below. For Clients, who have purchased
              Equipment or Services from a Vendor’s distributor, other
              conditions may apply.
              <br />
              <br />
              Please read the Terms carefully and, if you agree with them,
              confirm your consent by checking the “I accept the General Terms
              &amp; Conditions” checkbox in the Software. After confirming your
              consent, we shall consider that you are acquainted with and have
              accepted the Terms and agree to keep them.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Definitions</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Equipment – Agrio weather stations, including any components,
              spare parts or accessories for them; Software – the Agrio mobile
              application, as available on Google Play or Apple AppStore;
              <br />
              Services – the services, related to the Equipment and/or Software,
              such as real-time and historic data, agronomic indicators, weather
              forecast, alerts, software updates, technical support, etc.;
              <br />
              Client – a business client, as individualized by the e-mail,
              entered in the Software, who has acquired Equipment, Software or
              Services directly from the Vendor and uses them outside of the
              territory of North of Macedonia for his own business activity, and
              not for re-sale, leasing, renting out or similar transactions;
              <br />
              Vendor – AGROPTIMA, with registered address and headquarters in
              str. 15-th Korpus 2, Kisela Voda 1000 Skopje, company ID: 7270992,
              VAT ID: MK4043018524509, phone: +389 78 294 192, e-mail:
              agropskopje@gmail.com.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Subject</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The present Terms regulate the:
            </p>

            <div className="mb-8">
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Supply of Equipment;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Warranty and out-of-warranty Equipment service;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Software rights of use;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Transfer and storage of Equipment data;
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    Technical support and software update.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Equipment Specification
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The supplied Equipment conforms to the technical specifications,
              described at agrio.mk;
              <br /> The specific Equipment configuration, supplied to the
              Client, is described in the sales document(s); All Equipment is
              newly manufactured, fully-functional and has passed quality
              control checks before shipment.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Delivery</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The shipping, handling and insurance costs are for the account of
              the Client;
              <br />
              The Vendor may propose a shipment method, transportation and
              insurance company, quoting shipment and insurance costs and
              expected delivery time;
              <br />
              The Vendor guarantees that the packaging, loading, and proposed
              method of shipment are of sufficient standards, so that the
              Equipment may reasonably be expected to arrive at the Client’s
              site in merchantable condition, provided that the transportation
              and handling requirements on the packaging have been observed;
              <br />
              If the Client does not approve the proposed shipment method,
              transportation or insurance costs, he may organize the shipping
              and insurance himself;
              <br />
              The Vendor agrees to observe the proper issuing of all the
              necessary sales documentation (i.e. invoice, packing list, CMR,
              etc.).
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Acceptance</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Upon receipt of the Equipment, the Client must check it for
              visible defects:
              <br />
              <br />
              If the Client does not check the Equipment or does not find any
              defects, the Equipment is considered approved and accepted, except
              for latent defects (ones that cannot be discovered during a visual
              inspection). If the Client finds any defect upon the receipt of
              the Equipment, he must immediately inform the Vendor.
              <br />
              The Vendor cannot be held liable for defects due to the
              transportation, storage or handling of the Equipment, for which
              the Client can make an insurance claim or, in the absence of an
              insurance, can pay the Vendor for repair.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Warranty</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The warranty period of the Equipment is 24 months from the date of
              the purchase invoice, if not expressly provided otherwise;
              <br />
              The warranty covers the use cases, described in the User Manual,
              published on agrio.mk;
              <br />
              The warranty does not cover defects due to improper storage,
              transportation, packaging, handling, installation, operation,
              maintenance or application; misuse, abuse, modification;
              installation of spare parts or components, not purchased from the
              Vendor; unauthorized service, intentional damage, natural
              phenomena or disasters; damages from animals; vandalism; theft, as
              well as other damages, not due to Vendor’s fault.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Warranty Service
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              In case of an Equipment defect, the Client must send a service
              request, including pictures of the defective component and of the
              serial number, to <a href="mailto:tech@agrio.mk">tech@agrio.mk</a>
              .
              <br />
              Within 3 business days of the service request, the Vendor shall
              inform the Client whether the defect is covered by the warranty or
              not.
              <br />
              If the defect is covered by the warranty, the Vendor shall rectify
              the defect remotely, if this is technically possible.
              <br />
              If this is not technically possible, the Vendor shall either send
              the Client a new component of the same kind, or inform the Client
              to disassemble the defective component, observing the User Manual,
              and send it to the Vendor in its original packaging. The Vendor
              shall repair the component within 20 business days after receipt,
              and send it back to the Client.
              <br />
              The shipping, handling and insurance costs are for the account of
              the Client.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Out-of-warranty Service
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              If an Equipment defect is not covered by the warranty, or has
              occurred after the warranty period, the Client may use
              out-of-warranty service. The Vendor performs out-of-warranty
              repairs, after having informed the Client about the repair price
              and having received the Client’s advance payment. The shipping,
              handling and insurance costs are for the account of the Client.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Software Rights of Use
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              According to these Terms, the Vendor provides to the Client a
              non-exclusive, non-transferrable and limited right to use the
              Software for a limited term, defined in these Terms, by providing
              user account(s) to access the Software.
              <br />
              All functionalities of the Software are described on agrio.mk.
              <br /> A Client can use one user account on up to three devices.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Prices and Payment Terms
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Vendor quotes the price of Equipment and/or Services on
              agrio.mk. The Client pays for the Equipment and/or Services
              according to the methods of payment, specified by the Vendor on
              the sales document(s)
              <br />
              The price equals the net amount to be paid by the Client to the
              Vendor. Any sales taxes, duties, bank commissions, wire transfer
              costs or other fees, that may be associated with the transaction
              or due in the Client’s territory, shall be for the account of the
              Client.
              <br />
              The Vendor agrees to observe the proper issuing of all the
              necessary delivery documentation (i.e. invoice, packing list, CMR,
              etc.)
              <br />
              The Vendor reserves the right to change the prices from time to
              time by publishing the new prices on agrio.mk.
              <br />
              Any price changes of pre-paid Services do not affect the rights of
              Clients, who have already paid for such Services.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Order. Conclusion of the Contract
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Client may make an order for purchasing Equipment or Services
              from the Vendor through the electronic order form at agrio.mk
              (hereinafter “Electronic order form”) or by accepting a proforma-
              invoice from an authorized representative of the Vendor. To make
              the order, the Client needs to provide a name, invoice data,
              shipping address, and e-mail for contacts. By clicking the “Order”
              button or by paying for the proforma-invoice the Client confirms
              the type and quantity of the items ordered and accepts these
              Terms. In case the order has been made through the Electronic
              order form, the Vendor issues a proforma-invoice, containing the
              items ordered, unit price, quantity, amount, discount (if
              applicable), delivery term, and method of payment.
              <br />
              The delivery term starts from the payment receipt date.
              <br />
              By checking the “I accept the General Terms & Conditions” checkbox
              and/or clicking the “Order” button, the Client makes an electronic
              statement according to the Bulgarian Electronic Document and
              Electronic Signature Law, through which he/she declares, that he
              is acquainted with these Terms, accepts them and agrees to keep
              them. The text of the Terms is available on agrio.mk in a form,
              suitable to download and reproduce.
              <br />
              Before clicking the "Order" button, the Client may freely change
              the information provided by him/her in the Electronic order form.
              <br />
              Immediately after receiving an order through the Electronic order
              form, the Vendor confirms receiving the Client’s order and consent
              by sending a confirmation message to the Client’s e-mail, together
              with a sales document (proforma invoice). After the order
              confirmation by the Vendor, a contract between the Client and the
              Vendor is concluded.
              <br />
              The language of the contract is English.
              <br />
              By accepting these Terms the Client agrees to be officially
              contacted at the e-mail address, provided by him/her. The contract
              execution statement and its receipt confirmation are deemed
              received, when their addressees have access to them. A party to
              the contract with the Vendor is the Client, according to the data,
              submitted with the order. These Terms regulate the contractual
              relations between the Client and the Vendor, unless they agree
              otherwise in writing. In case of a contradiction between the Terms
              and any individual written agreements, the individual written
              agreements shall apply.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Access to Equipment Data and Services
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              In order to use data from Equipment and/or other Services, the
              Client shall download the Software from Google Play or App Store,
              install it on his/her mobile device, and enter his/her e-mail
              address. At this e- mail address the Client will automatically
              receive a code to activate his/her user account. If the Client has
              purchased Equipment and/or Services, the Client needs to enter in
              the Software the serial number and/or PIN code, received with the
              Equipment and/or Services. The Client then has access to Equipment
              data and/or other Services;
              <br />
              If the Client changes his/her mobile device, he/she may activate
              his/her user account on a new device by using the same e-mail
              address;
              <br />
              The Client may use Equipment data and/or Services free of charge
              on up to three devices for each piece of Equipment and/or Service
              purchased. The Client has to purchase an additional subscription
              in order to use Equipment data and/or Services on more devices.
              <br />
              The Client is solely responsible for protecting his/her user
              account, as well as for all acts, performed by him/her or by a
              third party by using that account.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Data Sharing</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              By purchasing a new piece of Equipment and a subscription with
              data sharing the Client participates in a common farming network.
              “Equipment data” means data, generated by the Equipment sensors,
              such as: precipitation quantity, soil temperature, soil moisture,
              wind speed, wind direction, air temperature, air humidity, air
              pressure, leaf wetness, date and time, battery voltage, solar
              panel voltage. Equipment Data is not personal data. The Client has
              no right to share data from Equipment, which is not owned by
              him/her.
              <br />
              By participating in the farming weather network and sharing
              Equipment Data, the Client can realise maximum benefits from the
              Equipment and Software, such as using data from neighbor
              Equipment, notifications for spreading of diseases, etc.
              <br />
              In case the Client purchases a subscription with data sharing,
              he/she expressly agrees, under these Terms, that Equipment Data,
              as well as all databases, derived from them, are exclusive
              property of the Vendor. The copyright on all databases, created
              using Equipment Data, belongs to the Vendor. The Vendor has the
              right to dispose of, use, reproduce and distribute shared
              Equipment Data and databases, including to provide them to third
              parties, without owing the Client any remuneration for that.
              <br />
              In case the Client does not want to participate in the farming
              weather network and does not want to share Equipment Data, he/she
              may select a subscription without data sharing. By purchasing such
              a subscription, the Equipment Data, generated during the term of
              the subscription, and the derived databases, are exclusive
              property of the Client, and he/she may dispose of, use, reproduce
              and distribute data from his/her Equipment and the derived
              databases, including to provide them to third parties, without
              owing the Vendor any remuneration for that.
              <br />
              For the avoidance of doubt, in case the Client continues to use
              the Software after the expiry of a subscription without data
              sharing, and does not renew that subscription, Equipment Data and
              the databases derived therefrom in the period without a
              subscription, are property of the Vendor.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Technical Support
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The technical support includes consulting for using the Equipment,
              Software and Services – on the phone and via e-mail – each
              business day from 09:00 to 18:00, Eastern European Time.
              <br />
              To Clients, who have purchased a subscription, the Vendor provides
              technical support without additional charge.
              <br />
              To Clients, who have not purchased a subscription, the Vendor
              provides technical support after having informed the Client about
              the price and having received the Client’s advance payment.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Data Transfer</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              For certain regions, specified on agrio.mk, the Vendor may supply
              the Equipment to the Client with installed SIM card for data and
              SMS for transfer of Equipment Data from the Equipment to the Agrio
              servers,.
              <br />
              The Client may not use the SIM card for any other purposes, except
              with the Equipment, purchased by the Vendor. Should the Client
              breach this condition, the Vendor reserves the right to deactivate
              the SIM card, without owing any compensation.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Data Access and Storage
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Client agrees, that in order to use the Equipment, Software
              and related Services, the Vendor has access to, can store and
              process data. Detailed information about the purpose of storage,
              processing and access to Equipment Datais provided in the Privacy
              Policy. The Client may at any time deny the Vendor the right to
              store, process or access data, by sending an e-mail to
              <a href="mailto:info@agrio.mk">info@agrio.mk</a>. In case of such
              a denial, the Vendor is relieved of his obligation to provide
              software functionalities and services, which require such data
              access, processing or storage.
              <br />
              The Vendor agrees to store on his server(s) data from Equipment,
              purchased by the Client, for a specified period of time. The
              period depends on whether the Client has purchased a subscription
              or not. With subscription data is stored for minimum 10 years, and
              without a subscription – for 3 months. The Client can receive
              information about the stored data at any time.
              <br />
              This section 16 does not apply to personal data, which shall be
              treated according to the Privacy Policy and the applicable data
              protection regulation.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Software Availability
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Vendor provides availability of the Software and the servers,
              where data is stored, minimum 99% of the time, calculated on a
              monthly basis. In case the Vendor breaches this obligation, the
              Client may claim a refund of part of the subscription,
              proportional to the duration of the Software outage.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Equipment and Software Updates
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Vendor updates the Software by adding new functionalities
              and/or fixing bugs, if such arise, minimum four times per annum.
              During such updates short Software outages may be possible, for
              which the Vendor will make reasonable efforts to inform the Client
              in advance. These outages do not preclude the Vendor’s obligation
              to provide Software availability in 99% of the time, calculated on
              a monthly basis.
              <br />
              The Vendor reserves the right to change and/or update the
              technical specifications of the Equipment, Software and/or
              Services, as well as to add new features, provided that the
              technical specifications of the Equipment, Software and/or
              Services have been improved, compared to the previous ones.
              <br />
              The Vendor shall not be required to provide any notice for any bug
              fixes, error corrections, or other modifications to the Equipment
              or the Software, in case that these modifications:
              <br />
              Will not affect the operability;
              <br />
              Will improve the security, or <br />
              Are required by any law, regulation, government entity, court
              order or other legal or contractual requirement.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Subscription</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The initial subscription with or without Equipment Data sharing is
              not included in the price of new Equipment and should be purchased
              separately. The prices for both types of subscription are quoted
              by the Vendor.
              <br />
              After the expiry of the initial subscription period, the Client
              may renew it for another period. If the Client does not want to
              renew the subscription, he/she may use certain Services and
              Software functionalities without subscription.
              <br />A subscription is non-transferrable.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Third-party Products and Services
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Vendor may provide to the Client products and/or services from
              third parties, such as mapping software, satellite imagery,
              geolocation, weather forecast, disease notifications, etc. The
              third-party products and services are provided under the terms and
              conditions of the respective third party.
              <br />
              By providing these products and/or services, the Vendor
              guarantees, that he has performed all necessary tests to ascertain
              their high reliability, availability and performance, but does not
              assume any liability for them.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Infrastructure</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              In order to use the Equipment, Software and/or Services and ensure
              their normal operation according to these Terms, the Client agrees
              to provide the necessary infrastructure and resources, including:
              <br />
              Proper location and conditions for operating the Equipment,
              according to the User Manual;
              <br />
              A pole (e.g. metal tube) for installing the Equipment, according
              to the User Manual;
              <br />
              Data SIM card with SMS service, if not provided by the Vendor;
              <br />
              Available mobile network coverage at the installation site;
              <br />
              A mobile device with internet connection and operating system
              Android, version 4.1 (or higher) or iOS, version 8.0 (or higher),
              where to use the Software;
              <br />
              User account in Google Play or App Store to download and update
              the Software.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Installation and Operation
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Client agrees to install the Equipment according to the User
              Manual, unless the Client and the Vendor have agreed otherwise.
              The Client agrees to observe the requirements for storage,
              transportation, packaging, handling, installation, operation,
              maintenance and application, described in the User Manual.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Correspondence</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Client shall notify the Vendor about any operation issues that
              may arise with the Equipment, Software and/or Services at the
              official electronic or postal address of the Vendor. The Vendor
              shall notify the Client at the e-mail address, provided by the
              Client during Software registration.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Intellectual Property and Rights
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Intellectual property rights (called „IP rights“) are trademarks,
              rights on trade names, logo, patents, useful models, rights on
              inventions, registered and unregistered rights on design,
              copyright and similar rights, rights on semi-conductor topography,
              databases, internet domains, know-how, trade secrets, rights on
              unfair competition claims, as well as any and all other
              intellectual property rights worldwide, regardless if registered
              on unregistered, including application for registering such rights
              and the rights to apply for such registration.
              <br />
              The Agrio trademark, the logo 1; the Software, including its
              source code, object code, executable files, algorithms,
              infrastructure, technical specifications, architecture, database,
              graphical user interface, know-how; IP rights on Equipment,
              including, design, architecture, semi-conductor topography,
              firmware, as well as the components of the Software and Equipment,
              except the components belonging to third parties, are exclusive
              property of the Vendor and he owns all IP rights on them according
              to the applicable legislation, including, but not limited to the
              right to sell, reproduce, distribute, license to third parties,
              use, modify, develop, adapt or in any other way dispose of, as
              well as to provide rights to third parties to dispose of the
              Software and Equipment.
              <br />
              Neither the Client, nor any other person may modify, reverse
              engineer, decompile, adapt, have unauthorised access, copy or in
              any other way reproduce or modify the Software or Equipment. In
              case the Client or its related person violate the IP rights of the
              Vendor, the Client owes a penalty amounting to five times the
              purchase value of the respective Equipment, Software or Service.
              This does not preclude the Vendor to seek additional compensation,
              in case of larger damages suffered.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Liability</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The Software, Equipment and Services are provided AS ARE and AS
              AVAILABLE. All use cases, for which Software, Equipment and
              Services are fit, are described in the User Manual.
              <br />
              To the extent permitted by law and EXCEPT AS EXPRESSLY STATED IN
              THESE TERMS, THE VENDOR DISCLAIMS ALL WARRANTIES AND CONDITIONS,
              EXPRESSED, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION THE
              IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE. THE VENDOR DOES NOT REPRESENT OR
              WARRANT THAT THE PRODUCTS WILL OPERATE SECURELY OR WITHOUT
              INTERRUPTION, EXCEPT AS EXPRESSLY STATED IN THESE TERMS. THE
              CLIENT ACKNOWLEDGES THAT HE HAS NOT ENTERED INTO THIS CONTRACT IN
              RELIANCE UPON ANY WARRANTY OR REPRESENTATION EXCEPT THOSE
              EXPRESSLY STATED IN THESE TERMS. NO ADVICE OR INFORMATION,
              OBTAINED THROUGH THE SOFTWARE SHALL CREATE ANY WARRANTY NOT
              EXPRESSLY STATED IN THESE TERMS.
              <br />
              The Vendor is not liable for ANY DIRECT, INDIRECT, INCIDENTAL or
              CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
              LOSS OF PROFITS, GOODWILL, USE, OR OTHER INTANGIBLE LOSSES
              RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE SOFTWARE;
              (II) THE INABILITY TO USE THE SOFTWARE TO ACCESS CONTENT OR DATA;
              (III) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES;
              (IV) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
              DATA; OR (V) ANY OTHER MATTER RELATING TO THE SOFTWARE.
              <br />
              The limitations of liability shall not apply in cases of intent or
              gross negligence of the Vendor.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Confidential Information
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The parties hereto agree not to disclose confidential information.
              Confidential information is all information, related to the
              organization, commercial activities, financial and accounting
              information, clients, suppliers, equipment, staff, inventory,
              source code, algorithms, infrastructure, technical specifications,
              architecture, databases, know-how, utility models, semi- conductor
              topography rights and rights in designs, drawings, diagrams as
              well as other information, pertaining to the Intellectual Property
              Rights of the parties, excluding the information, which a party
              has explicitly defined as non-confidential, commonly known facts,
              or information, which has been made public by a third party (for
              example, through advertisement, press releases, printed or web
              publications, etc.)
              <br />
              Each party agrees not to disclose confidential information to
              third parties in whatever form, as well as to ensure that its
              affiliates, employees, subcontractors and collaborators are bound
              by the same obligations for a period of three years after
              termination.
              <br />
              In case of infringement of the confidentiality obligations, the
              non-performing party owes to the other one compensation for the
              losses suffered.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Personal Data</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              By using the Equipment, Software and Services, the Client agrees
              to have his/her personal data processed and stored by the Vendor
              in accordance with the Privacy Policy, published at www.agrio.mk.
              The Vendor reserves the right to amend the Privacy Policy, after
              informing the Client thereof 30 days in advance by means of
              publication at www.agrio.mk. In case of personal data processing
              for the purpose of performing a particular task, the Vendor will
              process such data only with the Client’s express consent and to
              the extent, necessary to perform the Vendor’s obligations under
              these Terms and the Privacy Policy.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Term and Termination
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              <div className="my-2">
                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    A. Except as expressly stated in these Terms, the contract
                    between the parties shall be terminated in case of:
                    <br />
                    expiry of subscription or performance of Services, provided
                    by the Vendor to the Client according to these Terms;
                    <br />
                    mutual agreement of the parties for termination;
                    <br />
                    termination of the Vendor’s or the Client’s business
                    activity;
                    <br />
                    other cases, provided for in the applicable legislation.
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    B. In case either party breaches any provision of these
                    Terms or in an individual agreement between the parties: the
                    other party is relieved from its respective obligations for
                    the period of the first party’s breach. In that case, the
                    performing party does not owe any compensation to the
                    defaulting party;
                    <br />
                    the performing party may notify in writing the defaulting
                    party to correct the breach within 15 days. Should the
                    defaulting party not correct the breach within that term,
                    the performing party may terminate, without further notice,
                    the present Terms and, respectively, the performance of its
                    obligations under these Terms.
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    C. In case of early termination, which is not Vendor’s
                    fault, the Vendor does not owe a refund for a pre- paid
                    subscription.
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <span className="bullet me-3"></span>

                  <div className="text-gray-600 fw-semibold fs-6">
                    D. In case of termination, the clauses regarding
                    intellectual property and confidential information shall
                    survive.
                  </div>
                </div>
              </div>
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Force Majeure</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              Neither party shall be in default hereunder by reason of its delay
              in the performance of or failure to perform any of its obligations
              hereunder if such delay or failure is caused by natural disasters,
              strikes, war, riots, incendiaries, interference by civil or
              military authorities, compliance with governmental laws, rules and
              regulations, delays in transit or delivery, inability to secure
              necessary governmental priorities for materials, or any fault
              beyond its control or without its fault or negligence.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Dispute Resolution and Applicable Law
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              These Terms shall be governed and interpreted according to the
              laws of the Republic North of Macedonia.
              <br />
              All disputes, arising from or related to these Terms, shall be
              resolved by means of negotiations between the parties. In case the
              parties cannot reach an agreement, the dispute shall be referred
              to the competent Macedonian court.
              <br />
              Except as required by law, neither party, nor its affiliates,
              employees, subcontractors and collaborators, shall disclose the
              existence, contents or outcome of any dispute resolution between
              the parties, without the other party’s written consent.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Complete Agreement and Assignment
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              These Terms contain all the understandings and representations
              between the parties relating to the matters referred to herein,
              and supersede any arrangements previously entered into between
              them with respect thereto. The rights and obligations under these
              Terms cannot be assigned without the prior written consent of the
              other party, which consent will not be unreasonably withheld.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Amendments</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              In case of amendments to these Terms the Vendor shall notify the
              Client. Unless the Client expressly objects the amendments, the
              amended Terms shall become binding for the Client, as published at
              www.agrio.mk. The term for a Client’s objection shall be one month
              after being notified by the Vendor. Should the Client send his
              dissent about the amendments in writing to{" "}
              <a href="mailto:info@agrio.mk">info@agrio.mk</a>, these Terms
              shall be considered terminated. The termination does not affect
              the rights of a Client, who has already paid for a certain
              Equipment, Software or Service.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Severability</h2>

            <p className="fw-semibold fs-6 text-gray-600">
              In case any provision in these Terms shall be held invalid,
              illegal or unenforceable, the validity, legality and
              enforceability of the remaining provisions shall not in any way be
              affected or impaired thereby. The parties will replace the
              invalid, illegal or unenforceable provision with a valid, legal
              and enforceable one, which, as permitted by the applicable
              legislation, has an identical or similar business and legal
              meaning.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">
              Paragraph Headings
            </h2>

            <p className="fw-semibold fs-6 text-gray-600">
              The paragraph headings are for convenience only and shall not be
              deemed to affect in any way the language of the provisions to
              which they refer.
            </p>
          </div>

          <div className="mb-8">
            <p className="fw-semibold fs-6 text-gray-800">
              Last Updated: 29 Jаnuary 2023
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsEn;
