import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PrivacyEn from "./privacy/en";
import PrivacyMk from "./privacy/mk";
import "./webview.style.css";

const PrivacyPolicy = () => {
  const { lang = "en" } = useParams();
  switch (lang) {
    case "mk":
      return <PrivacyMk />;
    default:
      return <PrivacyEn />;
  }
};

export default PrivacyPolicy;
