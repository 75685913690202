import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Page from "../components/page.component";
import StationsCard from "../components/stations/stations-card.component";

const Stations = () => {
  const { t } = useTranslation();
  return (
    <Page title="Почетна" className={"app-container"}>
      <div className="row">
        <div className="col-4 mb-5">
          <h1>{t("stations")}</h1>
          <ul className="breadcrumb breadcrumb-dot fw-semibold text-gray-600 fs-7 my-1">
            <li className="breadcrumb-item text-gray-600 pe-0">
              <Link to="/" className="text-gray-600 text-hover-primary">
                {t("homepage")}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-500">{t("stations")}</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-lg-12">
          <StationsCard />
        </div>
      </div>
    </Page>
  );
};

export default Stations;
