import React from "react";
import { useTranslation } from "react-i18next";
import SingleAlarm from "./single-alarm.component";

const ListAlarms = ({ alarms, station }) => {
  const { t } = useTranslation();

  return (
    <div className="card card-flush h-xl-100">
      <div className="card-header pt-7">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-dark">
            {t("activeAlarms")}
          </span>
          <span className="text-gray-400 mt-1 fw-semibold fs-6">
            {t("total") + " " + alarms?.length + " " + t("alarms")}
          </span>
        </h3>
      </div>
      {alarms?.length > 0 ? (
        <div className="card-body">
          <div
            id="kt_table_widget_5_table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
          >
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer"
                id="kt_table_widget_5_table"
              >
                <thead>
                  <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th className="">{t("filter")}</th>
                    <th className="text-center">{t("border")}</th>
                    <th className="text-center">{t("value")}</th>
                    {!station ? (
                      <th className="text-center">{t("singleStation")}</th>
                    ) : (
                      ""
                    )}
                    <th className="text-end">{t("added")}</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="fw-bold text-gray-600">
                  {alarms &&
                    alarms.map((alarm) => (
                      <SingleAlarm
                        alarm={alarm}
                        key={alarm._id}
                        station={station}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="card-body">
          <div
            id="kt_table_widget_5_table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
          >
            <p>{station ? t("noAlarmsStation") : t("noAlarms")} </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListAlarms;
