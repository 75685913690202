import React from "react";
import { useParams } from "react-router-dom";
import FitrEn from "./fitr/en";
import FitrMk from "./fitr/mk";
import "./webview.style.css";

const AboutFitr = () => {
  const { lang = "en" } = useParams();
  switch (lang) {
    case "mk":
      return <FitrMk />;
    default:
      return <FitrEn />;
  }
};

export default AboutFitr;
