import React from "react";
import "../webview.style.css";

const FitrMk = () => {
  return (
    <div className="flex-lg-row-auto">
      <div className="card ">
        <div className="card-body">
          <div className="mb-7">
            <h2 className="fs-1 text-gray-800 w-bolder mb-6">Мисија и цели</h2>

            <p className="fw-bold text-gray-800">
              Фондот за иновации и технолошки развој е водечка владина
              институција за поддршка на стартапи и иновативни компании во
              Република Северна Македонија.
            </p>
            <p className="fw-semibold fs-6 text-gray-600">
              Во моментов, Фондот преку своите финансиски инструменти,
              ко-финансира 979 проекти со заеднички инвестиции од околу 112
              милиони евра. Од портфолиото на ФИТР дури 43% се стартап компании,
              претежно основани од млади луѓе. Со речиси 1,5 милиони евра,
              Фондот го поддржува и развојот на три акцелератори во државата: Х
              фактор, Сивус Акцелератор и Деловно-технолошки акцелератор УКИМ.
            </p>

            <p className="fw-bold text-gray-800">
              Главните приоритети на Фондот се подобрен пристап до финансиска
              поддршка за иновации и за технолошки развој и промовирање и
              поттикнување на иновациската дејност во Северна Македонија.
            </p>

            <div className="my-10">
              <p className="fw-bold text-gray-800">
                Нашите активности вклучуваат:
              </p>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Кoфинансирање стартапи, микро, мали и средни претпријатија,
                  регистрирани во Република Северна Македонија.
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Национален стартап совет чија цел е трансформација на
                  македонскиот стартап екосистем
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Програма за корпоративна иновативност со цел поттикнување на
                  соработката помеѓу приватниот сектор и стартапите
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Партнерство со Amazon Web Services преку која Фондот
                  обезбедува кредити за користење на оваа глобална платформа во
                  износ од 10.000, 25.000 и 100.000 американски долари
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Засебно портфолио за инвестиции во дигитализација на
                  земјоделството и јавните институции
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Изработка на Национална стратегија за вештачка интелигенција
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <span className="bullet me-3"></span>

                <div className="text-gray-600 fw-semibold fs-6">
                  Менторска поддршка за потенцијални и тековни корисници
                </div>
              </div>
            </div>
          </div>

          <p className="fw-bold text-gray-800">
            Фондот ги поддржува и талентираните основци, средношколци и
            студенти.
          </p>
          <p className="fw-semibold fs-6 text-gray-600">
            Во рамките на четирите предизвици „Предизвик за млади истражувачи“,
            беа наградени 109 училишни проекти во кои учествуваат над 728
            ученици и ментори од целата држава. За прв пат спроведовме и
            Предизвик за воспоставување на лабаратории за производство “Младите
            создаваат” . Преку поддршката на на Џуниор Ачивмент Македонија и
            програмата за претприемничко образование во сите средни училишта низ
            земјава , 728 ученици и 100 наставници. Во рамките на UPSHIFT
            програмата, Фондот награди 32 најуспешни идеи.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FitrMk;
