import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";


const ProtectedRoute = ({ children }) => {

    const token = useSelector((state) => state.user.token);

    // Check if token (isAuthenticated) is valid
    if ( token && token !== null && token !== undefined ) {
        return children;
    } else {
        return <Navigate to='/login' />
    }

};

export default ProtectedRoute;
