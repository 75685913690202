import axios from "axios";
import { useEffect } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeStations } from "../store/stations/stations.action";
import {
  checkCurrentUser,
  removeCurrentUser,
  setCurrentUser,
} from "../store/user/user.action";
import { LoaderContext } from "./loader.context";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { setPageLoader } = useContext(LoaderContext);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const token = useSelector((state) => state.user.token);
  const userInformation = useSelector((state) => state.user.userInformation);
  const { t } = useTranslation();

  useEffect(() => {
    token && userInformation && authCheck(token);
  }, [token]);

  /*------------------------------------------------------------------------------------
    // Check token and login user
    ------------------------------------------------------------------------------------*/
  const authCheck = () => {
    setPageLoader(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(checkCurrentUser(res.data));
        setPageLoader(false);
      })
      .catch((err) => {
        navigate("/dashboard");
        setPageLoader(false);
      });
  };

  /*------------------------------------------------------------------------------------
    // Login User
    ------------------------------------------------------------------------------------*/
  const authLogin = async (data) => {
    setPageLoader(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}user/login`, data)
      .then((res) => {
        dispatch(setCurrentUser(res.data));
        toast.success(t("successfullyLogged"));
        navigate("/dashboard");
      })
      .catch((err) => {
        toast.error(t("wrongLoginData"));
      });
    setPageLoader(false);
  };

  /*------------------------------------------------------------------------------------
    // Register new user
    ------------------------------------------------------------------------------------*/
  const authRegister = (data) => {
    setPageLoader(true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}user/register`, data)
        .then((res) => {
          resolve(res);
          setPageLoader(false);
        })
        .catch((err) => {
          reject(err);
          setPageLoader(false);
        });
    });
  };

  /*------------------------------------------------------------------------------------
    // Logout User
    ------------------------------------------------------------------------------------*/
  const authLogout = async () => {
    setPageLoader(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}user/logout`)
      .then((res) => {
        logoutUser();
      })
      .catch((err) => {});
    setPageLoader(false);
  };

  const logoutUser = async () => {
    dispatch(removeCurrentUser());
    dispatch(removeStations());
    toast.success(t("logoutSuccessfull"));
    navigate("/login");
  };

  const value = { authLogin, authRegister, logoutUser };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
