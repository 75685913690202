export const sensorsNames = {
  airTemperature: { name: "Температура на воздухот", symbol: "°C" },
  airHumidity: { name: "Влажност на воздухот", symbol: "hPa" },
  dewPoint: { name: "Точка на роса", symbol: "°C" },
  earthHumidity1: { name: "Влажност на почва", symbol: "%" },
  leafWetness1: { name: "Влажност на лист", symbol: "%" },
  precipitation: { name: "Врнежи", symbol: "mm/m2" },
  windSpeed: { name: "Брзина на ветер", symbol: "km/h" },
  batteryVoltage: { name: "Батерија", symbol: "V" },
};

export const comparings = {
  ">": { value: ">", name: "Се искачи над" },
  "<": { value: "<", name: "Падне под" },
  "<=": { value: "<=", name: "Помала или еднаква" },
  ">=": { value: ">=", name: "Поголема или еднаква" },
};

export const filters = [
  { value: "airTemperature", name: "Температура на воздухот" },
  { value: "airHumidity", name: "Влажност на воздухот" },
  { value: "dewPoint", name: "Точка на роса" },
  { value: "earthHumidity1", name: "Влажност на почва" },
  { value: "leafWetness1", name: "Влажност на лист" },
  { value: "precipitation", name: "Врнежи" },
  { value: "windSpeed", name: "Брзина на ветер" },
  { value: "batteryVoltage", name: "Батерија" },
];
