import { Fragment, useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import playstore from "../assets/images/play_store.png";
import appstore from "../assets/images/app_store.png";
import agrio from "../assets/images/agrio.png";
import agrooptima from "../assets/images/agroptima.png";
import fitr from "../assets/images/fitr.png";
import { NavLink } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import AboutMk from "../components/webview/about/mk";
import AboutEn from "../components/webview/about/en";
import AboutSq from "../components/webview/about/sq";
import AboutSr from "../components/webview/about/sr";

const Landing = () => {
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const { t } = useTranslation();

  const handleLanguage = (e) => {
    e.stopPropagation();
    setLanguageDropdown(!languageDropdown);
  };

  useEffect(() => {
    if (languageDropdown) {
      document.body.addEventListener("click", () => {
        setLanguageDropdown(false);
      });
    }
  }, [languageDropdown]);

  const changeLang = (lang) => {
    i18next.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  return (
    <Fragment>
      <div id="kt_header" className="app-header-primary app-header-landing">
        <div className="header-container container-xxl">
          <div className="app-container container-xxl d-flex align-content-between justify-content-between">
            <div className="page-title w-100 d-flex flex-column align-content-between justify-content-center flex-wrap py-3 py-lg-0 me-3">
              <h1 className="d-flex flex-column text-dark fw-bold my-1">
                <span className="text-white fs-1">
                  <img
                    height={50}
                    src={logo}
                    alt="Logo"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                </span>
              </h1>
              <div className="d-flex align-items-center flex-wrap">
                <div
                  onClick={handleLanguage}
                  className="btn btn-flex align-items-center position-relative bg-hover-white bg-hover-opacity-20 py-2 px-2 px-md-3 user_dropdown"
                >
                  <div className="me-3">
                    <div href="" className="btn bg-light position-relative">
                      {t("language")}
                    </div>
                  </div>

                  <div
                    className={`menu menu-sub menu-sub-dropdown position-absolute top-100 end-0 menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
                      languageDropdown ? "show" : ""
                    }`}
                  >
                    <div className="menu-item px-5">
                      <div
                        onClick={() => {
                          changeLang("mk");
                        }}
                        className={`menu-link px-5 ${
                          i18next.language === "mk" ? "active" : ""
                        }`}
                      >
                        Македонски
                      </div>
                    </div>
                    <div className="separator my-2"></div>
                    <div className="menu-item px-5">
                      <div
                        onClick={() => {
                          changeLang("en");
                        }}
                        className={`menu-link px-5 ${
                          i18next.language === "en" ? "active" : ""
                        }`}
                      >
                        English
                      </div>
                    </div>
                    <div className="separator my-2"></div>
                    <div className="menu-item px-5">
                      <div
                        onClick={() => {
                          changeLang("sq");
                        }}
                        className={`menu-link px-5 ${
                          i18next.language === "sq" ? "active" : ""
                        }`}
                      >
                        Squiptare
                      </div>
                    </div>
                    <div className="separator my-2"></div>
                    <div className="menu-item px-5">
                      <div
                        onClick={() => {
                          changeLang("sr");
                        }}
                        className={`menu-link px-5 ${
                          i18next.language === "sr" ? "active" : ""
                        }`}
                      >
                        Српски
                      </div>
                    </div>
                  </div>
                </div>
                <NavLink to="/dashboard" className="btn btn-dark">
                  {t("clientarea")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-header-secondary app-header-mobile-drawer mb-10">
        <div className="app-container container-xxl app-container-fit-mobile d-flex align-items-stretch">
          <div className="header-menu d-flex align-items-stretch w-100 align-items-center">
            <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-state-primary menu-title-gray-700 menu-arrow-gray-400 menu-bullet-gray-400 fw-semibold my-5 my-lg-0 align-items-center justify-content-center">
              <p className="fs-5 fw-semibold text-gray-600 mb-0">
                {t("agrioApps")}
              </p>
              <a
                href="https://apps.apple.com/us/app/agrio-mk/id6446647418"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appstore} alt="App Store" height={60} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.agriomk"
                target="_blank"
                rel="noreferrer"
              >
                <img src={playstore} alt="Google play Store" height={50} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl app-container-fit-mobile d-flex align-items-stretch mb-10 app-container">
        <div className="container-xl">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="row g-5 g-xl-8">
                <div className="col-xl-12">
                  {
                    {
                      mk: <AboutMk />,
                      sq: <AboutSq />,
                      sr: <AboutSr />,
                      en: <AboutEn />,
                    }[i18next.language]
                  }
                </div>
                <div className="card card-xl-stretch mb-xl-8">
                  <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        {t("agroptima")}
                      </span>
                    </h3>
                  </div>

                  <div className="card-body pt-2">
                    <img src={agrooptima} alt="Agroptima" className="w-100" />
                  </div>
                </div>
              </div>

              <div className="row g-5 g-xl-8">
                <div className="col-xl-6 g-5">
                  <div className="card card-xl-stretch mb-xl-8">
                    <div className="card-header border-0 py-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                          {t("agrio")}
                        </span>
                      </h3>
                    </div>

                    <div className="card-body pt-2 text-center">
                      <img src={agrio} alt="Agrio" className="w-50" />
                    </div>
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="card card-xl-stretch mb-xl-8">
                    <div className="card-header border-0">
                      <h3 className="card-title fw-bold text-dark">
                        {t("fitr")}
                      </h3>
                    </div>

                    <div className="card-body pt-2">
                      <img
                        src={fitr}
                        alt="Fond za inovacii"
                        className="w-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Landing;
