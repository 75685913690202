import i18next from "i18next";
import { useEffect } from "react";
import { useContext } from "react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { AuthContext } from "../context/auth.context";
import { LoaderContext } from "../context/loader.context";

const Header = () => {
  const user = useSelector((state) => state.user.userInformation);
  const [showDropdown, setShowDropdown] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const { logoutUser } = useContext(AuthContext);
  const { pageLoader } = useContext(LoaderContext);
  const { t } = useTranslation();

  const handleDropdown = (e) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
    setLanguageDropdown(false);
  };

  const handleLanguage = (e) => {
    e.stopPropagation();
    setShowDropdown(false);
    setLanguageDropdown(!languageDropdown);
  };

  useEffect(() => {
    if (showDropdown) {
      document.body.addEventListener("click", () => {
        setShowDropdown(false);
        setLanguageDropdown(false);
      });
    }

    if (languageDropdown) {
      document.body.addEventListener("click", () => {
        setLanguageDropdown(false);
        setShowDropdown(false);
      });
    }
  }, [showDropdown, languageDropdown]);

  const changeLang = (lang) => {
    i18next.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const handleLogout = (e) => {
    logoutUser();
  };

  return (
    <Fragment>
      {pageLoader && <div className="screen-loader">Loading...</div>}
      <div id="kt_header" className="app-header-primary">
        <div className="header-container container-xxl">
          <div className="app-container container-xxl d-flex align-items-stretch justify-content-between">
            <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 py-3 py-lg-0 me-3">
              <h1 className="d-flex flex-column text-dark fw-bold my-1">
                <span className="text-white fs-1">
                  <img
                    height={50}
                    src={logo}
                    alt="Logo"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                </span>
              </h1>
            </div>

            <div className="d-flex align-items-center flex-wrap">
              <div
                onClick={handleLanguage}
                className="btn btn-flex align-items-center position-relative bg-hover-white bg-hover-opacity-20 py-2 px-2 px-md-3 user_dropdown"
              >
                <div className="me-3">
                  <div href="" className="btn bg-light position-relative">
                    {t("language")}
                  </div>
                </div>

                <div
                  className={`menu menu-sub menu-sub-dropdown position-absolute top-100 end-0 menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
                    languageDropdown ? "show" : ""
                  }`}
                >
                  <div className="menu-item px-5">
                    <div
                      onClick={() => {
                        changeLang("mk");
                      }}
                      className={`menu-link px-5 ${
                        i18next.language === "mk" ? "active" : ""
                      }`}
                    >
                      Македонски
                    </div>
                  </div>
                  <div className="separator my-2"></div>
                  <div className="menu-item px-5">
                    <div
                      onClick={() => {
                        changeLang("en");
                      }}
                      className={`menu-link px-5 ${
                        i18next.language === "en" ? "active" : ""
                      }`}
                    >
                      English
                    </div>
                  </div>
                  <div className="separator my-2"></div>
                  <div className="menu-item px-5">
                    <div
                      onClick={() => {
                        changeLang("sq");
                      }}
                      className={`menu-link px-5 ${
                        i18next.language === "sq" ? "active" : ""
                      }`}
                    >
                      Squiptare
                    </div>
                  </div>
                  <div className="separator my-2"></div>
                  <div className="menu-item px-5">
                    <div
                      onClick={() => {
                        changeLang("sr");
                      }}
                      className={`menu-link px-5 ${
                        i18next.language === "sr" ? "active" : ""
                      }`}
                    >
                      Serbian
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={handleDropdown}
                className="btn btn-flex align-items-center position-relative bg-hover-white bg-hover-opacity-10 py-2 px-2 px-md-3 user_dropdown"
              >
                <div className="d-none d-md-flex flex-column align-items-end justify-content-center me-2 me-md-4">
                  <span className="text-white fs-8 opacity-75 fw-semibold lh-1 mb-1">
                    {t("welcome")}
                  </span>
                  <span className="text-white fs-8 fw-bold lh-1">
                    {user.name}
                  </span>
                </div>
                <div className="me-3">
                  <div
                    href=""
                    className="btn btn-icon bg-light position-relative"
                  >
                    <span className="svg-icon svg-icon-2 svg-icon-dark">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                          fill="currentColor"
                        ></path>
                        <rect
                          opacity="0.3"
                          x="8"
                          y="3"
                          width="8"
                          height="8"
                          rx="4"
                          fill="currentColor"
                        ></rect>
                      </svg>
                    </span>
                  </div>
                </div>

                <div
                  className={`menu menu-sub menu-sub-dropdown position-absolute top-100 end-0 menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
                    showDropdown ? "show" : ""
                  }`}
                >
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      <div className="d-flex flex-column">
                        <div className="fw-bold d-flex fs-5">{user.name}</div>
                        <div
                          href="#"
                          className="d-flex fw-semibold text-muted text-hover-primary fs-7"
                        >
                          {user.email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator my-2"></div>
                  <div className="menu-item px-5">
                    <a href="#" className="menu-link px-5">
                      {t("myProfile")}
                    </a>
                  </div>
                  <div className="menu-item px-5">
                    <a href="#" className="menu-link px-5">
                      {t("myInvoices")}
                    </a>
                  </div>
                  <div className="separator my-2"></div>
                  <div className="menu-item px-5">
                    <div onClick={handleLogout} className="menu-link px-5">
                      {t("logout")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-header-secondary app-header-mobile-drawer mb-10">
        <div className="app-container container-xxl app-container-fit-mobile d-flex align-items-stretch">
          <div className="header-menu d-flex align-items-stretch w-100">
            <div
              className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-state-primary menu-title-gray-700 menu-arrow-gray-400 menu-bullet-gray-400 fw-semibold my-5 my-lg-0 align-items-stretch"
              id="#kt_header_menu"
            >
              <div className="menu-item me-0 me-lg-2">
                <NavLink to="/dashboard" className="menu-link py-3">
                  <span className="menu-title">
                    <span className="menu-text">{t("homepage")}</span>
                    <span className="menu-desc">{t("baseData")}</span>
                  </span>
                  <span className="menu-arrow d-lg-none"></span>
                </NavLink>
              </div>
              <div className="menu-item me-0 me-lg-2">
                <NavLink to="/stations" className="menu-link py-3">
                  <span className="menu-title">
                    <span className="menu-text">{t("stations")}</span>
                    <span className="menu-desc">{t("availableStations")}</span>
                  </span>
                  <span className="menu-arrow d-lg-none"></span>
                </NavLink>
              </div>
              <div className="menu-item me-0 me-lg-2">
                <NavLink to="/alarms" className="menu-link py-3">
                  <span className="menu-title">
                    <span className="menu-text">{t("alarms")}</span>
                    <span className="menu-desc">{t("yourAlarms")}</span>
                  </span>
                  <span className="menu-arrow d-lg-none"></span>
                </NavLink>
              </div>
              <div className="menu-item me-0 me-lg-2">
                <NavLink to="/events" className="menu-link py-3">
                  <span className="menu-title">
                    <span className="menu-text">{t("events")}</span>
                    <span className="menu-desc">{t("yourEvents")}</span>
                  </span>
                  <span className="menu-arrow d-lg-none"></span>
                </NavLink>
              </div>
              <div className="menu-item me-0 me-lg-2">
                <span
                  className="menu-link py-3"
                  style={{ cursor: "not-allowed" }}
                >
                  <span className="menu-title">
                    <span className="menu-text text-gray-300">
                      {t("invoices")}
                    </span>
                    <span className="menu-desc text-gray-300">{t("soon")}</span>
                  </span>
                  <span className="menu-arrow d-lg-none"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
