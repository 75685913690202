import React, { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import highchartsAccessibility from "highcharts/modules/accessibility";

import "./webview.style.css";
import axios from "axios";
import { groupingUnits, seriesList } from "../../config/highcharts.config";
import { useTranslation } from "react-i18next";

require("highcharts/modules/windbarb")(Highcharts);

highchartsAccessibility(Highcharts);
highchartsMore(Highcharts);

const WebviewDiseases = () => {
  const [series, setSeries] = useState(null);
  const token = useSelector((state) => state.user.token);
  const [chart, setChart] = useState(null);
  const { t } = useTranslation();

  // Get UUID Parameter from url
  const { filter, id } = useParams();

  function sortFunction(a, b) {
    if (a[0] === b[0]) {
      return 0;
    } else {
      return a[0] < b[0] ? -1 : 1;
    }
  }

  useEffect(() => {
    let _data = [];
    axios
      .get(`${process.env.REACT_APP_API_URL}station/history/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        Object.keys(data).forEach(function (key, index) {
          let item = data[key];

          let temp = Math.round(item[filter] * 10) / 10;

          _data.push([item.timestamp, temp]);
        });

        _data.sort(sortFunction);

        let serie = seriesList[filter];
        serie.data = _data;

        setSeries(serie);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (series) {
      let config = {
        scrollbar: {
          enabled: true,
        },

        rangeSelector: {
          allButtonsEnabled: true,
          buttonSpacing: 10,
          buttons: [
            {
              type: "day",
              count: 7,
              text: "7 дена",
            },
            {
              type: "day",
              count: 30,
              text: "30 дена",
            },
            {
              type: "month",
              count: 3,
              text: "3 месеци",
            },
            {
              type: "month",
              count: 6,
              text: "6 месеци",
            },
            {
              type: "year",
              count: 1,
              text: "1 година",
            },
            {
              type: "ytd",
              text: "YTD",
            },
          ],
          selected: 1,
          buttonTheme: {
            width: "auto",
            fill: "transparent",
            padding: 10,
            style: {
              color: "#a1a5b7",
            },
            states: {
              hover: {},
              select: {
                fill: "#f5f8fa",
                style: {
                  color: "#7e8299",
                },
              },
              disabled: {
                style: {
                  opacity: 0.8,
                },
              },
            },
          },
          inputBoxStyle: {
            fontSize: 12,
            right: "80px",
            marginBottom: "50px",
          },
          inputBoxBackground: "#f5f8fa",
          inputStyle: {
            color: "#7e8299",
            fontWeight: "bold",
          },
        },
        chart: {
          zoomType: "x",
          backgroundColor: "transparent",
          prototype: {
            viewData: {},
          },
        },

        exporting: {
          enabled: false,
          showTable: false,
          tableCaption: "Data table",
          csv: {
            dateFormat: "%Y-%m-%d  %H:%M:%S",
          },
        },

        credits: {
          enabled: false,
        },

        options: {
          style: {
            fontFamily: "Roboto, sans-serif",
          },
        },

        xAxis: {
          type: "datetime",
          enabled: false,
          title: {
            text: "Време на мерење",
          },
        },

        plotOptions: {
          series: {
            showInLegend: true,
            dataGrouping: {
              units: groupingUnits,
              dateTimeLabelFormats: {
                millisecond: ["%A, %b %e, %H:%M:%S.%L"],
                second: ["%A, %b %e, %H:%M:%S"],
                minute: ["%A, %b %e, %H:%M"],
                hour: ["%A, %b %e, %H:%M"],
                day: ["%A, %b %e, %Y"],
                week: ["Неделата од %b %e, %Y"],
                month: ["%B %Y"],
                year: ["%Y"],
              },
            },
          },
        },

        tooltip: {
          positioner: function () {
            return {
              // right aligned
              x: this.chart.chartWidth - this.label.width,
              y: 20, // align to title
            };
          },
          borderWidth: 0,
          backgroundColor: "none",
          shadow: false,
          xDateFormat: "%d-%m-%Y  %H:%M:%S",
        },

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  enabled: false,
                },
              },
            },
          ],
        },

        series: [series],
      };

      // init the chart
      setChart(config);
    }
  }, [series]);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {chart ? (
        <HighchartsReact
          height={400}
          highcharts={Highcharts}
          constructorType={"stockChart"}
          allowChartUpdate={false}
          options={chart}
        />
      ) : (
        <div
          style={{
            display: "block",
            textAlign: "center",
            width: "100%",
            marginTop: "200px",
            fontSize: 14,
          }}
        >
          <div className="loader">
            <p className="text">{t("dataLoading")}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebviewDiseases;
